import { ScienceOutlined } from '@mui/icons-material';
import {
  Box,
  FormControl,
  FormHelperText,
  InputAdornment,
  Stack,
  TextField,
  Tooltip,
} from '@mui/material';
import DirectionPointer from '../DirectionPointer';
import { memo, useCallback } from 'react';

export default memo(function PropagationConfigField(props) {
  const {
    label,
    description,
    unit,
    value,
    mapHeading,
    onChange,
    onPaste,
    useLive,
    isExperimental,
    TextFieldProps,
  } = props;
  // console.log('rendering PropagationConfigField');

  return (
    <FormControl fullWidth>
      <Stack direction='row' spacing={1} alignItems='center'>
        <TextField
          size='small'
          type='text'
          inputMode='decimal'
          label={label}
          value={value}
          onChange={onChange}
          onPaste={onPaste || null}
          slotProps={{
            htmlInput: {
              inputMode: 'decimal',
            },
            input: {
              endAdornment: unit && (
                <InputAdornment position='end'>{unit}</InputAdornment>
              ),
            },
          }}
          fullWidth
          {...TextFieldProps}
        />
        {label === 'Wind Direction' && (
          <Box sx={{ opacity: useLive ? 0.38 : 1 }}>
            <DirectionPointer
              direction={
                !isNaN(value) && !isNaN(mapHeading)
                  ? (Number(value - mapHeading) + 180) % 360
                  : 180
              }
              BoxSx={{ border: '1px solid rgba(255,255,255,0.3)' }}
            />
          </Box>
        )}
        {/* {liveToggle && (
					<ToggleButton
						size='small'
						color='primary'
						value='live'
						selected={useLive}
						onChange={onLiveChange}
						sx={{ height: 'fit-content' }}
					>
						Live
					</ToggleButton>
				)} */}
        {isExperimental && (
          <Tooltip title='This feature is experimental.'>
            <ScienceOutlined />
          </Tooltip>
        )}
      </Stack>
      {description && (
        <FormHelperText sx={{ ml: 0 }}>{description}</FormHelperText>
      )}
    </FormControl>
  );
});
