import { IconButton, Slider, Stack, Tooltip, Typography } from '@mui/material';
import GoogleMapsCustomControl from './GoogleMapsCustomControl';
import { FastRewind, Pause, PlayArrow, Replay } from '@mui/icons-material';
import { usePropagationLayerState } from '../../contexts/PropagationLayerStateProvider';
import { usePropagationLayer } from '../../hooks/usePropagationLayer';
import { useCallback, useEffect } from 'react';
import PlaybackSpeed from './PlaybackSpeed';

export default function PlaybackControls(props) {
  const { propLayerData, activePropIndex, setActivePropIndex } =
    usePropagationLayerState();
  const { isPlaybackRunning, startPlayback, stopPlayback, playbackSpeedRef } =
    usePropagationLayer();

  useEffect(() => {
    if (propLayerData) startPlayback();
  }, [propLayerData?.timestamp]);

  const minutesToHMM = useCallback((minutes) => {
    const hours = Math.floor(minutes / 60);
    const mins = minutes % 60;
    return `${hours}:${String(mins).padStart(2, '0')}`;
  }, []);

  return (
    propLayerData && (
      <GoogleMapsCustomControl
        BoxProps={{
          sx: (theme) => ({
            display: 'flex',
            alignItems: 'center',
            p: 1,
            [theme.breakpoints.down('sm')]: {
              width: 'calc(100vw - 20px)',
              marginBottom: '24px',
            },
          }),
        }}
      >
        <Stack
          direction='row'
          spacing={3}
          alignItems='center'
          flexWrap='wrap'
          width='100%'
        >
          <Stack
            direction='row'
            sx={(theme) => ({
              [theme.breakpoints.down('sm')]: {
                order: 1,
                ml: '12px !important',
              },
            })}
          >
            <Tooltip title='Rewind'>
              <IconButton
                onClick={() => {
                  stopPlayback();
                  setActivePropIndex(0);
                }}
                size='small'
                color='inherit'
                // sx={{
                //   '&:hover': {
                //     backgroundColor: 'rgba(0,0,0, 0.05)',
                //   },
                // }}
              >
                <FastRewind color='inherit' />
              </IconButton>
            </Tooltip>
            <Tooltip
              title={
                !isPlaybackRunning
                  ? activePropIndex >= propLayerData.images.length - 1
                    ? 'Restart'
                    : 'Play'
                  : 'Pause'
              }
            >
              <IconButton
                onClick={!isPlaybackRunning ? startPlayback : stopPlayback}
                size='small'
                color='inherit'
                sx={{
                  '&:hover': {
                    backgroundColor: 'rgba(0,0,0, 0.05)',
                  },
                }}
              >
                {!isPlaybackRunning ? (
                  activePropIndex >= propLayerData.images.length - 1 ? (
                    <Replay color='inherit' />
                  ) : (
                    <PlayArrow color='inherit' />
                  )
                ) : (
                  <Pause color='inherit' />
                )}
              </IconButton>
            </Tooltip>
          </Stack>
          <Slider
            onChange={(e, value) => {
              if (e) {
                stopPlayback();
                setActivePropIndex(value);
              }
            }}
            aria-label='Propagation Time'
            value={activePropIndex}
            max={propLayerData.images.length - 1}
            sx={(theme) => ({
              width: { sm: '40vw', md: '30vw', lg: '20vw' },
              [theme.breakpoints.down('sm')]: {
                width: '100%',
                order: 0,
                marginLeft: 'auto',
                marginRight: '24px !important',
              },
            })}
          />
          <Stack
            direction='row'
            spacing={0.5}
            alignItems='center'
            pr='5px'
            sx={(theme) => ({
              [theme.breakpoints.down('sm')]: {
                order: 1,
                margin: 'auto !important',
                transform: 'translate(-50%, 0)',
              },
            })}
          >
            <Typography variant='caption' minWidth='3ch' textAlign='right'>
              {minutesToHMM(activePropIndex + 1)}
            </Typography>
            <Typography variant='caption'>/</Typography>
            <Typography variant='caption'>
              {minutesToHMM(propLayerData.images.length)}
            </Typography>
            <PlaybackSpeed />
          </Stack>
        </Stack>
      </GoogleMapsCustomControl>
    )
  );
}
