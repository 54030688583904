import { useCallback } from "react";
import { useWindLayerState } from "../contexts/WindLayerStateProvider";
import { useMap } from "@vis.gl/react-google-maps";
import { HeatmapLayer } from "@deck.gl/aggregation-layers";
import { usePropagationNewJobState } from "../contexts/PropagationNewJobStateProvider";

export const useWindLayer = () => {
  const {
    windManifest,
    setWindManifest,
    activeWindIndex,
    setActiveWindIndex,
    setWindLayer,
    windLayerEnabled,
  } = useWindLayerState();
  const { parameters, setParameters } = usePropagationNewJobState();
  const map = useMap("propagation-map");

  const getWindManifest = useCallback(async () => {
    const response = await fetch(
      "https://risk.alertwest.live/wx/manifest.json"
    );
    const data = await response.json();
    data.sort((a, b) => {
      return a.forecast - b.forecast;
    });
    setWindManifest(data);

    const epoch = Math.floor(Date.now() / 1000);
    let closestIndex = 0;
    let minDiff = Math.abs(data[0].forecast - epoch);
    for (let i = 1; i < data.length; i++) {
      const newDiff = Math.abs(data[i].forecast - epoch);
      if (newDiff < minDiff) {
        closestIndex = i;
        minDiff = newDiff;
      }
    }
    setActiveWindIndex(closestIndex);
  }, [setActiveWindIndex, setWindManifest]);

  const updateWindLayer = useCallback(
    async (newIndex = null) => {
      if (!windManifest || !map) return;
      // load manifest data if necessary
      let data = null;
      if (!windManifest[activeWindIndex].data) {
        const response = await fetch(
          `https://risk.alertwest.live/${windManifest[activeWindIndex].file}`
        );
        data = await response.text();
        data = data.split("\n").map((line) => {
          const row = line.split(",");
          if ("lat" in row) return;
          return new Float32Array([
            parseFloat(row[0]),
            parseFloat(row[1]),
            parseInt(row[2]),
            parseInt(row[3]),
            parseInt(row[4]),
          ]);
        });
        let newWindManifest = { ...windManifest };
        newWindManifest[activeWindIndex].data = data;
        setWindManifest(newWindManifest);

        if (parameters.lng && parameters.lat) {
          let closest = null;
          let closestDistance = 0;
          data.forEach((point) => {
            let distance = Math.sqrt(
              Math.pow(point[1] - parameters.lng, 2) +
                Math.pow(point[0] - parameters.lat, 2)
            );
            if (!closest || distance < closestDistance) {
              closest = point;
              closestDistance = distance;
            }
          });
          setParameters((prev) => ({
            ...prev,
            windSpeed: closest[2],
            windDirection: (closest[3] + 180) % 360,
          }));
        }
      }

      // update layer
      const bounds = map.getBounds();
      if (!bounds) return;
      const ne = bounds.getNorthEast();
      const sw = bounds.getSouthWest();
      const intermediateData = windManifest[activeWindIndex].data.filter(
        (feature) => {
          const lat = feature[0];
          const lng = feature[1];
          return (
            lat >= sw.lat() &&
            lat <= ne.lat() &&
            lng >= sw.lng() &&
            lng <= ne.lng()
          );
        }
      );

      const getPixelRadius = (zoom) => {
        if (zoom < 5) return 5;
        if (zoom < 6) return 10;
        if (zoom < 7) return 15;
        if (zoom < 8) return 25;
        if (zoom < 9) return 30;
        if (zoom < 10) return 50;
        if (zoom < 11) return 120;
        if (zoom < 12) return 240;
        if (zoom < 13) return 480;
        if (zoom < 14) return 960;
        return 960;
      };

      const zoom = map.getZoom();
      setWindLayer(
        new HeatmapLayer({
          id: "wind-layer",
          data: intermediateData,
          visible: windLayerEnabled && zoom < 14,
          getWeight: (d) => d[2],
          radiusPixels: getPixelRadius(zoom),
          getPosition: (d) => [d[1], d[0]],
          colorRange: [
            [0, 255, 0, 150], // 0-10 mph - green
            [255, 255, 0, 150], // 10-20 mph - yellow
            [255, 165, 0, 150], // 20-30 mph - orange
            [255, 0, 0, 150], // 30-40 mph - red
            [255, 0, 255, 150], // 40+ mph - purple
          ],
          colorDomain: [0, 40],
          aggregation: "MEAN",
          pickable: false,
          weightsTextureSize: 2048,
        })
      );

      if (windManifest[newIndex]?.data) {
        let closest = null;
        let closestDistance = 0;
        windManifest[newIndex].data.forEach((point) => {
          let distance = Math.sqrt(
            Math.pow(point[1] - parameters.lng, 2) +
              Math.pow(point[0] - parameters.lat, 2)
          );
          if (!closest || distance < closestDistance) {
            closest = point;
            closestDistance = distance;
          }
        });
        if (parameters.lat && parameters.lng) {
          setParameters((prev) => ({
            ...prev,
            windSpeed: closest[2],
            windDirection: (closest[3] + 180) % 360,
          }));
        }
      }
    },
    [
      windManifest,
      setWindManifest,
      activeWindIndex,
      map,
      windLayerEnabled,
      parameters,
      setParameters,
      setWindLayer,
    ]
  );

  return { getWindManifest, updateWindLayer };
};
