import { ControlPosition, MapControl } from '@vis.gl/react-google-maps';
import PlaybackControls from './PlaybackControls';
import WindDateTimePicker from '../layers/wind-layer/WindDateTimePicker';
import LayersPopover from './LayersPopover';
import FirePerimetersTimeRangePicker from '../layers/fire-perimeters-layer/FirePerimetersTimeRangePicker';
import { Stack } from '@mui/material';
import { useFirePerimetersLayerState } from '../../contexts/FirePerimetersLayerStateProvider';
import AttributionPin from '../../AttributionPin';

export default function MapControls(props) {
  const { firePerimetersLayerEnabled } = useFirePerimetersLayerState();

  return (
    <>
      <MapControl position={ControlPosition.BLOCK_END_INLINE_CENTER}>
        <PlaybackControls />
      </MapControl>
      <MapControl position={ControlPosition.TOP_RIGHT}>
        <Stack direction='row-reverse'>
          <WindDateTimePicker />
          {firePerimetersLayerEnabled && <FirePerimetersTimeRangePicker />}
        </Stack>
      </MapControl>
      <MapControl position={ControlPosition.RIGHT_TOP}>
        <LayersPopover />
      </MapControl>
      <MapControl position={ControlPosition.LEFT_BOTTOM}>
        <AttributionPin />
      </MapControl>
    </>
  );
}
