import dayjs from 'dayjs';
import { createContext, useContext, useEffect, useState } from 'react';

const FirePerimetersLayerStateContext = createContext();

export default function FirePerimetersLayerStateProvider(props) {
  const [firePerimetersLayerEnabled, setFirePerimetersLayerEnabled] =
    useState(false);
  const [firePerimeterLayerIsHovered, setFirePerimeterLayerIsHovered] =
    useState(false);
  const [firePerimetersTimeRange, setFirePerimetersTimeRange] = useState([
    dayjs().year(2024),
    dayjs().year(2025),
  ]);
  const [selectedFirePerimeterData, setSelectedFirePerimeterData] =
    useState(null);
  const [firePerimetersLayer, setFirePerimetersLayer] = useState(null);

  return (
    <FirePerimetersLayerStateContext.Provider
      value={{
        firePerimetersLayerEnabled,
        setFirePerimetersLayerEnabled,
        firePerimeterLayerIsHovered,
        setFirePerimeterLayerIsHovered,
        firePerimetersTimeRange,
        setFirePerimetersTimeRange,
        selectedFirePerimeterData,
        setSelectedFirePerimeterData,
        firePerimetersLayer,
        setFirePerimetersLayer,
      }}
    >
      {props.children}
    </FirePerimetersLayerStateContext.Provider>
  );
}

export const useFirePerimetersLayerState = () =>
  useContext(FirePerimetersLayerStateContext);
