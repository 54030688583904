export const scenarios = [
  {
    parameters: {
      name: 'Combs',
      lat: 35.492794,
      lng: -100.896383,
      windSpeed: 36,
      windDirection: 173,
      fmc: 0.15,
      year: 2025,
    },
  },
  {
    parameters: {
      name: 'Olinda',
      lat: 20.803882,
      lng: -156.281078,
      windSpeed: 30,
      windDirection: 20,
      fmc: 0.15,
      year: 2023,
    },
  },
  {
    parameters: {
      name: '$38B example-Jan 7-2025',
      lat: 34.127698,
      lng: -118.404066,
      windSpeed: 42,
      windDirection: 303,
      fmc: 0.12,
      year: 2025,
    },
  },
  {
    parameters: {
      name: 'Hurst',
      lat: 34.328614,
      lng: -118.477507,
      windSpeed: 43,
      windDirection: 311,
      fmc: 0.1,
      year: 2025,
    },
  },
  {
    parameters: {
      name: 'Kenneth',
      lat: 34.182756,
      lng: -118.67399,
      windSpeed: 29,
      windDirection: 332,
      fmc: 0.1,
      year: 2025,
    },
  },
  {
    parameters: {
      name: 'Puu Hele',
      lat: 20.815498,
      lng: -156.509042,
      windSpeed: 23,
      windDirection: 275,
      fmc: 0.15,
      year: 2024,
    },
  },
  {
    parameters: {
      name: 'Pulehu',
      lat: 20.788408,
      lng: -156.366766,
      windSpeed: 40,
      windDirection: 10,
      fmc: 0.15,
      year: 2023,
    },
  },
  {
    parameters: {
      name: 'Kawaihae',
      lat: 20.011234,
      lng: -155.813515,
      windSpeed: 35,
      windDirection: 0,
      fmc: 0.15,
      year: 2023,
    },
  },
  {
    parameters: {
      name: 'Blue Hills',
      lat: 35.693084,
      lng: -101.667482,
      windSpeed: 23,
      windDirection: 143,
      fmc: 0.15,
      year: 2025,
    },
  },
  {
    parameters: {
      name: 'Border 2',
      lat: 32.596655,
      lng: -116.848127,
      windSpeed: 42,
      windDirection: 350,
      fmc: 0.15,
      year: 2025,
    },
  },
  {
    parameters: {
      name: 'Mosquito',
      lat: 39.006778,
      lng: -120.743769,
      windSpeed: 5,
      windDirection: 90,
      fmc: 0.12,
      year: 2022,
    },
  },
  {
    parameters: {
      name: 'Mill-bad',
      lat: 41.433225,
      lng: -122.385116,
      windSpeed: 20,
      windDirection: 75,
      fmc: 0.12,
      year: 2022,
    },
  },
  {
    parameters: {
      name: 'Davis',
      lat: 39.303083,
      lng: -119.829495,
      windSpeed: 25,
      windDirection: 135,
      fmc: 0.12,
      year: 2025,
    },
  },
  {
    parameters: {
      name: 'Hughes Fire',
      lat: 34.570779,
      lng: -118.561804,
      windSpeed: 35,
      windDirection: 315,
      fmc: 0.12,
      year: 2025,
    },
  },
  {
    parameters: {
      name: 'Tubbs Fire',
      lat: 38.610427,
      lng: -122.628039,
      windSpeed: 60,
      windDirection: 320,
      fmc: 0.17,
      year: 2017,
    },
  },
  {
    parameters: {
      name: 'Marshall Fire',
      lat: 39.956173,
      lng: -105.230447,
      windSpeed: 60,
      windDirection: 190,
      fmc: 0.12,
      year: 2021,
    },
  },
  {
    parameters: {
      name: 'Dixie Fire',
      lat: 39.875946,
      lng: -121.377224,
      windSpeed: 35,
      windDirection: 105,
      fmc: 0.08,
      year: 2021,
    },
  },
  {
    parameters: {
      name: 'Park Fire',
      lat: 39.771753,
      lng: -121.765409,
      windSpeed: 30,
      windDirection: 90,
      fmc: 0.12,
      year: 2024,
    },
  },
  {
    parameters: {
      name: 'Mountain Fire',
      lat: 34.319049,
      lng: -118.967801,
      windSpeed: 50,
      windDirection: 330,
      fmc: 0.16,
      year: 2024,
    },
  },
  {
    parameters: {
      name: 'Camp Fire',
      lat: 39.813543,
      lng: -121.434802,
      windSpeed: 40,
      windDirection: 345,
      fmc: 0.05,
      year: 2018,
    },
  },
  {
    parameters: {
      name: 'Palisades Fire',
      lat: 34.073224,
      lng: -118.544298,
      windSpeed: 40,
      windDirection: 295,
      fmc: 0.16,
      year: 2025,
    },
  },
  {
    parameters: {
      name: 'Eaton Fire',
      lat: 34.185422,
      lng: -118.089811,
      windSpeed: 35,
      windDirection: 306,
      fmc: 0.16,
      year: 2025,
    },
  },
  {
    parameters: {
      name: 'Lahaina Fire',
      lat: 20.884786,
      lng: -156.662611,
      windSpeed: 80,
      windDirection: 345,
      fmc: 0.14,
      year: 2023,
    },
  },
];
