import { BitmapLayer, PolygonLayer } from '@deck.gl/layers';
import { usePropagationLayerState } from '../contexts/PropagationLayerStateProvider';
import { useCallback, useMemo, useRef, useState } from 'react';

export const usePropagationLayer = () => {
  const {
    propLayerData,
    activePropIndex,
    setActivePropIndex,
    playbackSpeed,
    setPlaybackSpeed,
    isPlaybackRunning,
    setIsPlaybackRunning,
    playbackIntervalRef,
  } = usePropagationLayerState();

  const propLayer = useMemo(() => {
    if (!propLayerData?.images[activePropIndex]) return null;

    return new BitmapLayer({
      id: 'propagation-layer',
      bounds: propLayerData.bounds,
      image: propLayerData.images[activePropIndex],
      opacity: 0.8,
      textureParameters: {
        minFilter: 'nearest',
        magFilter: 'nearest',
      },
    });
  }, [propLayerData?.bounds, propLayerData?.images, activePropIndex]);

  const boundsLayer = useMemo(() => {
    if (!propLayerData?.bounds) return null;
    const bounds = propLayerData.bounds;

    return new PolygonLayer({
      id: 'bounds-layer',
      data: [
        [
          [bounds[0], bounds[1]],
          [bounds[2], bounds[1]],
          [bounds[2], bounds[3]],
          [bounds[0], bounds[3]],
          [bounds[0], bounds[1]],
        ],
      ],
      getPolygon: (d) => d,
      getLineColor: [0, 0, 0],
      // getFillColor: [0, 0, 0, 0],
      lineWidthMinPixels: 2,
      stroked: true,
      filled: false,
    });
  }, [propLayerData?.bounds]);

  const propBounds = propLayerData?.bounds;

  const updatePlaybackSpeed = useCallback(
    (speed) => {
      setPlaybackSpeed(speed);
      if (!playbackIntervalRef.current) return;

      clearInterval(playbackIntervalRef.current);
      playbackIntervalRef.current = setInterval(() => {
        if (!playbackIntervalRef.current) return;
        setActivePropIndex((prev) => {
          // Stop when we reach the end of playback, otherwise go to the next frame
          if (prev >= propLayerData.images.length - 1) {
            stopPlayback();
            return prev;
          }
          return prev + 1;
        });
      }, speed);
    },
    [propLayerData?.images, setActivePropIndex, stopPlayback, setPlaybackSpeed]
  );

  const stopPlayback = useCallback(() => {
    if (playbackIntervalRef.current) {
      clearInterval(playbackIntervalRef.current);
      playbackIntervalRef.current = null;
    }
    setIsPlaybackRunning(false);
  }, [setIsPlaybackRunning]);

  const startPlayback = useCallback(() => {
    if (playbackIntervalRef.current) return;

    // If we're at the end of the playback, restart it
    setActivePropIndex((prev) =>
      prev >= propLayerData.images.length - 1 ? 0 : prev
    );
    setIsPlaybackRunning(true);

    playbackIntervalRef.current = setInterval(() => {
      if (!playbackIntervalRef.current) return;
      setActivePropIndex((prev) => {
        // Stop when we reach the end of playback, otherwise go to the next frame
        if (prev >= propLayerData.images.length - 1) {
          stopPlayback();
          return prev;
        }
        return prev + 1;
      });
    }, playbackSpeed);
  }, [
    propLayerData?.images,
    setActivePropIndex,
    stopPlayback,
    setIsPlaybackRunning,
    playbackSpeed,
  ]);

  return {
    propLayer,
    boundsLayer,
    propBounds,
    startPlayback,
    stopPlayback,
    isPlaybackRunning,
    playbackSpeed,
    updatePlaybackSpeed,
  };
};
