import { StrictMode } from 'react';
import AppPropagation from './AppPropagation.jsx';
import { LicenseInfo } from '@mui/x-license';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Layout from './Layout.jsx';
import '@fontsource/montserrat';
import { theme } from './theme.js';
import { CssBaseline, ThemeProvider } from '@mui/material';

LicenseInfo.setLicenseKey(__MUI_X_LICENSE_KEY);

export default function Root() {
  return (
    <StrictMode>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <BrowserRouter>
          <Routes>
            <Route path='/propagation' element={<Layout />}>
              <Route index element={<AppPropagation />} />
              <Route path='demo' element={<AppPropagation pageMode='demo' />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </StrictMode>
  );
}
