export const quantize = (data, steps, min, max) => {
  if (min === undefined) {
    min = Math.min(...data);
  }
  if (max === undefined) {
    max = Math.max(...data);
  }
  const stepSize = (max - min) / steps;
  const breakpoints = [null];
  for (let i = 1; i < steps; i++) {
    breakpoints.push(min + stepSize * i);
  }
  return breakpoints;
};

export const quantile = (data, steps) => {
  const sortedData = [...data].sort((a, b) => a - b);
  const breakpoints = [null];
  for (let i = 1; i < steps; i++) {
    const quantileIndex = Math.floor((i * sortedData.length) / steps) - 1;
    breakpoints.push(sortedData[quantileIndex]);
  }
  return breakpoints;
};

export const natural = (data, steps) => {
  const sortedData = [...data].sort((a, b) => a - b);
  const breakpoints = [null];
  const stepSize = Math.floor(sortedData.length / steps);
  for (let i = 1; i < steps; i++) {
    breakpoints.push(
      sortedData[Math.min(i * stepSize - 1, sortedData.length - 1)]
    );
  }
  return breakpoints;
};

export const stdDev = (data, steps) => {
  const mean = data.reduce((acc, val) => acc + val, 0) / data.length;
  const stdDev = Math.sqrt(
    data.reduce((acc, val) => acc + Math.pow(val - mean, 2), 0) / data.length
  );
  const breakpoints = [null];
  for (let i = 1; i < steps; i++) {
    breakpoints.push(mean + stdDev * i);
  }
  return breakpoints;
};
