import { Box, Container, CssBaseline, ThemeProvider } from '@mui/material';
import PropagationMap from './components/map/PropagationMap';
import PropagationSidebar from './components/sidebar/PropagationSidebar';
import { APIProvider } from '@vis.gl/react-google-maps';
import DamagesChart from './components/map/DamagesChart';
import PropagationNewJobStateProvider from './components/contexts/PropagationNewJobStateProvider.jsx';
import PropagationLayersProvider from './components/contexts/PropagationLayerStateProvider.jsx';
import PropagationHistoryProvider from './components/contexts/PropagationHistoryProvider.jsx';
import WindLayerStateProvider from './components/contexts/WindLayerStateProvider.jsx';
import FirePerimetersLayerStateProvider from './components/contexts/FirePerimetersLayerStateProvider.jsx';
import AssetRiskLayersStateProvider from './components/contexts/AssetRiskLayersStateProvider.jsx';

export default function AppPropagation(props) {
  const { pageMode } = props;
  const mapApiKey = __GOOGLE_MAPS_API_KEY;

  return (
    <PropagationLayersProvider>
      <WindLayerStateProvider>
        <FirePerimetersLayerStateProvider>
          <PropagationHistoryProvider>
            <PropagationNewJobStateProvider>
              <AssetRiskLayersStateProvider>
                <Container
                  maxWidth={false}
                  disableGutters
                  sx={{ display: 'flex', height: 'calc(100vh - 64px)' }}
                >
                  <APIProvider apiKey={mapApiKey}>
                    <Box
                      height='100%'
                      flex={1}
                      overflow='hidden'
                      display='flex'
                      flexDirection='column'
                    >
                      <Box flex={1}>
                        <PropagationMap />
                      </Box>
                      <Box height={300} p={2} pr={0}>
                        <DamagesChart />
                      </Box>
                    </Box>
                    <Box height='100%' flexShrink={1}>
                      <PropagationSidebar pageMode={pageMode} />
                    </Box>
                  </APIProvider>
                </Container>
              </AssetRiskLayersStateProvider>
            </PropagationNewJobStateProvider>
          </PropagationHistoryProvider>
        </FirePerimetersLayerStateProvider>
      </WindLayerStateProvider>
    </PropagationLayersProvider>
  );
}
