import {
  Clear,
  ExpandLess,
  ExpandMore,
  FileDownload,
  Visibility,
  VisibilityOff,
} from '@mui/icons-material';
import {
  Box,
  Collapse,
  Divider,
  Fade,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { useState } from 'react';
import AssetRiskColorConfig from './AssetRiskColorConfig';

const CollapsiblePaper = ({ header, PaperProps, children }) => {
  const [expanded, setExpanded] = useState(false);
  const theme = useTheme();

  return (
    <Paper {...PaperProps}>
      <Stack
        direction='row'
        justifyContent='space-between'
        alignItems='center'
        p={1}
        onClick={() => setExpanded((prev) => !prev)}
        onKeyDown={(e) => {
          if (e.key === 'Enter' || e.key === ' ') {
            e.preventDefault();
            setExpanded((prev) => !prev);
          }
        }}
        borderRadius={`4px 4px ${expanded ? 0 : '4px'} ${expanded ? 0 : '4px'}`}
        role='button'
        tabindex={0}
        sx={{
          ':not(:has(.MuiButtonBase-root:hover)):hover': {
            backgroundColor: theme.palette.action.hover,
            cursor: 'pointer',
          },
        }}
      >
        {header}
        {expanded ? (
          <ExpandLess color='secondary' />
        ) : (
          <ExpandMore color='secondary' />
        )}
      </Stack>
      <Collapse in={expanded}>{children}</Collapse>
    </Paper>
  );
};

export default function AssetRiskLayerConfig(props) {
  const { dataset, datasetName } = props;
  const [isHeaderHovered, setIsHeaderHovered] = useState(false);

  return (
    <CollapsiblePaper
      header={
        <Stack
          direction='row'
          alignItems='center'
          justifyContent='space-between'
          flex={1}
          onMouseEnter={() => setIsHeaderHovered(true)}
          onMouseLeave={() => setIsHeaderHovered(false)}
        >
          <Typography>{datasetName}</Typography>
          <Stack direction='row' alignItems='center'>
            <Fade in={isHeaderHovered}>
              <Tooltip title='Download CSV'>
                <IconButton
                  color='primary'
                  size='small'
                  href={dataset.url}
                  onClick={(e) => e.stopPropagation()}
                >
                  <FileDownload />
                </IconButton>
              </Tooltip>
            </Fade>
            <IconButton
              color='secondary'
              size='small'
              onClick={(e) => {
                e.stopPropagation();
                dataset.update('style.visible', !dataset.style.visible);
              }}
            >
              {dataset.style.visible ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </Stack>
        </Stack>
      }
    >
      <Divider />
      <Stack spacing={1} p={1}>
        <CollapsiblePaper
          header={<Typography>Fill Color</Typography>}
          PaperProps={{ elevation: 3 }}
          IconButtonProps={{ size: 'small' }}
        >
          <Divider />
          <AssetRiskColorConfig dataset={dataset} property='fillColor' />
        </CollapsiblePaper>
        <CollapsiblePaper
          header={<Typography>Outline</Typography>}
          PaperProps={{ elevation: 3 }}
          IconButtonProps={{ size: 'small' }}
        >
          <Divider />
          <AssetRiskColorConfig dataset={dataset} property='lineColor' />
        </CollapsiblePaper>
        <CollapsiblePaper
          header={<Typography>Radius</Typography>}
          PaperProps={{ elevation: 3 }}
          IconButtonProps={{ size: 'small' }}
        >
          <Divider />
          <Stack spacing={1} p={2}>
            <Stack direction='row' spacing={1} alignContent='center'>
              <FormControl fullWidth size='small'>
                <InputLabel id='radius-based-on-label' shrink>
                  Radius Based On
                </InputLabel>
                <Select
                  labelId='radius-based-on-label'
                  id='radius-based-on-select'
                  value={dataset.style.radius.parameter || ''}
                  onChange={(e) =>
                    dataset.update('style.radius.parameter', e.target.value)
                  }
                  label='Radius Based On'
                  displayEmpty
                >
                  <MenuItem value='' sx={{ display: 'none' }}>
                    <em>Select a field</em>
                  </MenuItem>
                  {Object.keys(dataset.minValues).map((key) => (
                    <MenuItem value={key}>{key}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              <IconButton
                size='small'
                color='secondary'
                onClick={() => dataset.update('style.radius.parameter', '')}
                sx={{ height: 'fit-content' }}
              >
                <Clear size='small' />
              </IconButton>
            </Stack>
          </Stack>
        </CollapsiblePaper>
      </Stack>
    </CollapsiblePaper>
  );
}
