import { usePropagationHistory } from '../contexts/PropagationHistoryProvider';
import { usePropagationLayerState } from '../contexts/PropagationLayerStateProvider';
import { usePropagationNewJobState } from '../contexts/PropagationNewJobStateProvider';
import NdChaos from '../../functions/NdChaos';
import { usePropagationLayer } from './usePropagationLayer';
import { authedFetch } from '@alertwest/auth';

export const usePropagationNewJob = () => {
  const { setIsRunning } = usePropagationNewJobState();
  const { setPropLayerData, setActivePropIndex } = usePropagationLayerState();
  const { stopPlayback } = usePropagationLayer();
  const { addJobToHistory, cacheScenarioResults } = usePropagationHistory();

  const runJob = async (jobParameters, scenarioIndex = null) => {
    setIsRunning(true);
    stopPlayback();
    console.log('starting job', jobParameters);

    try {
      const lat = parseFloat(jobParameters.lat);
      const lon = parseFloat(jobParameters.lng);

      const response = await authedFetch(
        `https://${window.location.host.includes('staging') ? 'staging.' : ''}propagation.alertwest.com/api/propagation/invoke`,
        {
          method: 'POST',
          // headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            coords: {
              lat,
              lon,
            },
            wind_speed: Number(jobParameters.windSpeed),
            wind_dir: Number(
              jobParameters.windDirection + (scenarioIndex === null && 270)
            ),
            fmc:
              Number(jobParameters.fmc) * (scenarioIndex === null ? 0.01 : 1),
            humidity: 0.15,
            radius: 10000,
            steps: 501,
            version: 'v2',
            colormap: 'false',
          }),
        }
      );
      const buffer = await response.arrayBuffer();
      /*
						Spec:
							Acres Burned:   uint32[500]  (500 × 4 bytes = 2000 bytes)
							Struct Count:   uint32[500]  (500 × 4 bytes = 2000 bytes)
							Financial Loss: uint64[500] (500 × 8 bytes = 4000 bytes)
							Request ID:     char[36] (72 bytes)
							Bounds:         float64[4] (32 bytes)
							PNG Data:       [:] (variable - the rest of the buffer)
					*/
      let offset = 0;
      // let dataView = new DataView(buffer);
      const acresBurned = new Float32Array(buffer.slice(offset, offset + 2000));
      offset += 2000;
      const structsBurned = new Uint32Array(
        buffer.slice(offset, offset + 2000)
      );
      offset += 2000;
      let financialLoss = new BigUint64Array(
        buffer.slice(offset, offset + 4000)
      );
      financialLoss = Array.from(financialLoss, Number);
      offset += 4000;
      const requestId = new TextDecoder()
        .decode(buffer.slice(offset, offset + 36))
        .trim();
      offset += 36;
      const bounds = new Float64Array(buffer.slice(offset, offset + 32));
      offset += 32;
      const report = {
        acresBurned,
        structsBurned,
        financialLoss,
      };
      // console.log(report);

      // Convert binary to base64 in chunks to avoid call stack size exceeded error
      const chunk = 8192;
      const bytes = new Uint8Array(buffer.slice(offset));
      const len = bytes.byteLength;
      let binary = '';
      for (let i = 0; i < len; i += chunk) {
        const slice = bytes.slice(i, Math.min(i + chunk, len));
        binary += String.fromCharCode.apply(null, slice);
      }
      let imageUrl = `data:image/png;base64,${btoa(binary)}`;

      let img = new Image();
      img.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        canvas.width = img.width;
        canvas.height = img.height;
        ctx.drawImage(img, 0, 0);

        let ndManager = new NdChaos(ctx, img.width, img.height, 500).then(
          (ndManager) => {
            const timestamp = Date.now();
            const images = ndManager.imageUrls;
            setPropLayerData({
              bounds,
              images,
              report,
              timestamp,
            });
            setActivePropIndex(0);
            if (scenarioIndex === null) {
              addJobToHistory({
                parameters: jobParameters,
                report,
                bounds,
                images,
                timestamp,
              });
            } else {
              cacheScenarioResults(
                {
                  parameters: jobParameters,
                  report,
                  bounds,
                  images,
                },
                scenarioIndex
              );
            }
            // console.log('finished job', Date.now());
          }
        );
      };
      img.src = imageUrl;
    } catch (error) {
      console.error(error);
    }
    setIsRunning(false);
  };

  return { runJob };
};
