import { Box, Paper, Stack } from '@mui/material';

export default function ColorPicker(props) {
  const { color, setColor, resetColorPreset, hues, shades } = props;

  return (
    <Paper variant='outlined' sx={{ p: 1, width: '100%' }}>
      <Stack direction='row'>
        {hues.map((hue) => (
          <Stack flex={1}>
            {shades.map((shade) => (
              <Box
                onClick={() => {
                  setColor(hue[shade]);
                  if (resetColorPreset) {
                    // console.log('resetting color preset');
                    resetColorPreset();
                  }
                }}
                flex={1}
                bgcolor={hue[shade]}
                sx={{
                  minWidth: 13,
                  aspectRatio: 1,
                  cursor: 'pointer',
                  padding: color !== hue[shade] ? '2px' : 0,
                  border:
                    color !== hue[shade]
                      ? 'none'
                      : `2px solid ${shade < 500 ? '#000' : '#fff'}`,
                  ':hover': {
                    padding: 0,
                    border: `2px solid ${shade < 500 ? '#000' : '#fff'}`,
                  },
                }}
              />
            ))}
          </Stack>
        ))}
      </Stack>
    </Paper>
  );
}
