import { useTheme } from '@emotion/react';
import {
  Box,
  Divider,
  FormControl,
  FormControlLabel,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select,
  Stack,
  Switch,
  Typography,
} from '@mui/material';
import chroma from 'chroma-js';
import { useCallback } from 'react';

export default function AssetRiskColorPaletteSelect(props) {
  const {
    colorArray,
    updateColorArray,
    colorPreset,
    updateColorPreset,
    gradient,
    toggleGradient,
    reverse,
    toggleReverse,
  } = props;
  // console.log('current colorPreset', colorPreset);
  const theme = useTheme();

  const handleColorArrayChange = useCallback(
    (e) => {
      const newColors = chroma.scale(e.target.value).colors(colorArray.length);
      const newArray = colorArray.map((object, index) => {
        return {
          ...object,
          color: newColors[index],
        };
      });
      updateColorArray(newArray);
      updateColorPreset(e.target.value);
    },
    [colorArray, updateColorArray, updateColorPreset]
  );

  return (
    <Stack direction='row' spacing={1}>
      <FormControl fullWidth size='small'>
        <InputLabel id='color-palette-select-label' shrink>
          Color Palette
        </InputLabel>
        <Select
          value={colorPreset || ''}
          label='Color Palette'
          labelId='color-palette-select-label'
          inputProps={{ sx: { display: 'flex', alignItems: 'center' } }}
          MenuProps={{ sx: { maxHeight: 400 } }}
          onChange={handleColorArrayChange}
          displayEmpty
        >
          <ListSubheader
            sx={{
              backgroundImage: 'var(--Paper-overlay)',
              boxShadow: '0 4px 6px -2px rgba(0, 0, 0, 0.3)',
            }}
          >
            <Stack py={0.5} spacing={1}>
              <Stack
                direction='row'
                justifyContent='space-between'
                alignItems='center'
              >
                <Typography>Gradient</Typography>
                <Switch
                  size='small'
                  checked={gradient}
                  onChange={() => toggleGradient(gradient)}
                />
              </Stack>
              <Stack
                direction='row'
                justifyContent='space-between'
                alignItems='center'
              >
                <Typography>Reverse</Typography>
                <Switch
                  size='small'
                  checked={reverse}
                  onChange={() => toggleReverse(reverse)}
                />
              </Stack>
            </Stack>
          </ListSubheader>

          {(!colorPreset || colorPreset === '') && (
            <MenuItem value=''>
              <Stack direction='row' height={15} width='100%'>
                {!gradient
                  ? colorArray.map((object) => (
                      <Box bgcolor={object.color} flex={1} />
                    ))
                  : chroma
                      .scale(colorArray.map((object) => object.color))
                      .colors(100)
                      .map((color) => <Box bgcolor={color} flex={1} />)}
              </Stack>
            </MenuItem>
          )}
          {Object.keys(chroma.brewer).map((key) => {
            const chromaScale = chroma.scale(key);
            const chromaColors = chromaScale.colors(
              gradient ? 100 : colorArray.length
            );
            if (reverse) chromaColors.reverse();
            return (
              <MenuItem value={key}>
                <Stack direction='row' height={15} width='100%'>
                  {!gradient
                    ? chromaColors.map((color) => (
                        <Box bgcolor={color} flex={1} />
                      ))
                    : chromaColors.map((color) => (
                        <Box bgcolor={color} flex={1} />
                      ))}
                </Stack>
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </Stack>
  );
}
