import { Divider, Paper, Stack, TextField, Typography } from '@mui/material';
import { useAssetRiskLayersState } from '../../contexts/AssetRiskLayersStateProvider';

export default function AssetRiskFilterConfig(props) {
  const { parameter } = props;
  const { assetRiskFilters, setAssetRiskFilters } = useAssetRiskLayersState();

  return (
    <Paper sx={{ p: 1 }}>
      <Typography>{parameter}</Typography>
      <Stack spacing={1} p={1}>
        <TextField
          label='Minimum'
          type='number'
          size='small'
          value={assetRiskFilters[parameter].min}
          onChange={(e) => {
            setAssetRiskFilters((prev) => {
              const newObject = { ...prev };
              newObject[parameter].min = e.target.value;
              return newObject;
            });
          }}
          slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
        />
        <TextField
          label='Maximum'
          type='number'
          size='small'
          shrink='true'
          value={assetRiskFilters[parameter].max}
          onChange={(e) => {
            setAssetRiskFilters((prev) => {
              const newObject = { ...prev };
              newObject[parameter].max = e.target.value;
              return newObject;
            });
          }}
          slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
        />
      </Stack>
    </Paper>
  );
}
