import {
  Box,
  Collapse,
  FormControl,
  InputLabel,
  Paper,
  Select,
  Stack,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { presetHues, presetShades } from '../../../../assets/preset_colors';
import ColorPicker from './ColorPicker';

export default function AssetRiskColorPicker(props) {
  const { color, updateColor, resetColorPreset } = props;
  const [open, setOpen] = useState(false);

  return (
    <Box>
      <FormControl fullWidth size='small'>
        <InputLabel id='fill-color-picker-label' shrink>
          Color
        </InputLabel>
        <Select
          label='Color'
          labelId='fill-color-picker-label'
          id='fill-color-picker-select'
          value={color}
          onClick={() => setOpen((prev) => !prev)}
          open={open}
          renderValue={() => (
            <Stack direction='row' alignItems='center' spacing={1}>
              <Box height={15} width={30} bgcolor={color} />
              <Typography>{color}</Typography>
            </Stack>
          )}
          MenuProps={{ sx: { display: 'none' } }}
        />
      </FormControl>
      <Collapse in={open}>
        <ColorPicker
          color={color}
          setColor={updateColor}
          resetColorPreset={resetColorPreset}
          hues={presetHues}
          shades={presetShades}
        />
      </Collapse>
    </Box>
  );
}
