import {
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
} from '@mui/material';
import AssetRiskColorPicker from './AssetRiskColorPicker';
import { Clear } from '@mui/icons-material';
import AssetRiskColorScaleConfig from './AssetRiskColorScaleConfig';
import AssetRiskColorRanges from './AssetRiskColorRanges';

export default function AssetRiskColorConfig(props) {
  const { dataset, property } = props;

  return (
    <Stack spacing={1.5} p={2}>
      <Stack direction='row' spacing={1} alignContent='center'>
        <FormControl fullWidth size='small'>
          <InputLabel id={`${property}-based-on-label`} shrink>
            Color Based On
          </InputLabel>
          <Select
            labelId={`${property}-based-on-label`}
            id={`${property}-based-on-select`}
            value={dataset.style[property].parameter || ''}
            onChange={(e) =>
              dataset.update(`style.${property}.parameter`, e.target.value)
            }
            label='Color Based On'
            displayEmpty
          >
            <MenuItem value='' sx={{ display: 'none' }}>
              <em>Select a field</em>
            </MenuItem>
            {Object.keys(dataset.minValues).map((key) => (
              <MenuItem value={key}>{key}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <IconButton
          size='small'
          color='secondary'
          onClick={() => dataset.update(`style.${property}.parameter`, '')}
          sx={{ height: 'fit-content' }}
        >
          <Clear size='small' />
        </IconButton>
      </Stack>

      {!dataset.style[property].parameter ? (
        <AssetRiskColorPicker
          color={dataset.style[property].color}
          updateColor={(color) =>
            dataset.update(`style.${property}.color`, color)
          }
          resetColorPreset={() =>
            dataset.update(`style.${property}.colorPreset`, '')
          }
        />
      ) : (
        <>
          <AssetRiskColorScaleConfig
            colorScale={dataset.style[property].colorScale}
            updateColorScale={(newScale) =>
              dataset.update(`style.${property}.colorScale`, newScale)
            }
            colorArray={dataset.style[property].colorArray}
            updateColorArray={(newArray) =>
              dataset.update(`style.${property}.colorArray`, newArray)
            }
            colorPreset={dataset.style[property].colorPreset}
            updateColorPreset={(newValue) =>
              dataset.update(`style.${property}.colorPreset`, newValue)
            }
            gradient={dataset.style[property].gradient}
            toggleGradient={(cur) =>
              dataset.update(`style.${property}.gradient`, !cur)
            }
            reverse={dataset.style[property].reverse}
            toggleReverse={(cur) => {
              dataset.update(`style.${property}.reverse`, !cur);
              const reversedColors = dataset.style[property].colorArray
                .map((object) => object.color)
                .reverse();
              const newArray = dataset.style[property].colorArray.map(
                (object, index) => ({
                  ...object,
                  color: reversedColors[index],
                })
              );
              dataset.update(
                `style.${property}.colorArray`,
                newArray,
                dataset.style[property].colorArray.reverse()
              );
            }}
          />
          <AssetRiskColorRanges
            dataset={dataset}
            property={property}
            updateColorArray={(newArray) =>
              dataset.update(`style.${property}.colorArray`, newArray)
            }
            updateColorPreset={(newValue) =>
              dataset.update(`style.${property}.colorPreset`, newValue)
            }
          />
        </>
      )}
    </Stack>
  );
}
