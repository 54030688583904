import { createContext, useCallback, useContext, useState } from "react";
import { scenarios as scenariosList } from "../../assets/scenarios";

const PropagationHistoryContext = createContext();

export default function PropagationHistoryProvider(props) {
  const [history, setHistory] = useState([]);
  const [scenarios, setScenarios] = useState(scenariosList);

  const addJobToHistory = useCallback(
    (job) => setHistory((prev) => [...prev, job]),
    [setHistory]
  );

  const cacheScenarioResults = (job, scenarioIndex) => {
    let newScenarios = [...scenarios];
    let scenarioName = scenarios[scenarioIndex].parameters.name;
    newScenarios[scenarioIndex] = job;
    newScenarios[scenarioIndex].parameters.name = scenarioName;
    setScenarios(newScenarios);
  };

  return (
    <PropagationHistoryContext.Provider
      value={{ history, scenarios, addJobToHistory, cacheScenarioResults }}
    >
      {props.children}
    </PropagationHistoryContext.Provider>
  );
}

export const usePropagationHistory = () =>
  useContext(PropagationHistoryContext);
