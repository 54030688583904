import { AddCircleOutline } from '@mui/icons-material';
import {
  Button,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Paper,
  Popover,
  Stack,
} from '@mui/material';
import { useState } from 'react';
import { useAssetRiskLayersState } from '../../../contexts/AssetRiskLayersStateProvider';
import AssetRiskLayerConfig from './AssetRiskLayerConfig';

export default function AssetRiskLayersTab(props) {
  const [anchorEl, setAnchorEl] = useState(null);
  const { assetRiskDatasets, addDataset, availableDatasets } =
    useAssetRiskLayersState();

  console.log(availableDatasets);

  return (
    <>
      <Popover
        open={anchorEl ? true : false}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <List>
          {availableDatasets
            .filter((dataset) => !assetRiskDatasets[dataset.name])
            .map((dataset) => (
              <ListItem disablePadding>
                <ListItemButton
                  onClick={() => {
                    addDataset(
                      dataset.name,
                      `https://staging.propagation.alertwest.com/${dataset.url}`
                    );
                    setAnchorEl(null);
                  }}
                >
                  <ListItemText primary={dataset.name} />
                </ListItemButton>
              </ListItem>
            ))}
        </List>
      </Popover>
      <Button
        onClick={(e) => setAnchorEl(e.currentTarget)}
        variant='contained'
        startIcon={<AddCircleOutline />}
        disabled={
          availableDatasets.filter(
            (dataset) => !assetRiskDatasets[dataset.name]
          ).length < 1
        }
        // sx={{ width: 'fit-content', mx: 'auto !important' }}
      >
        Add Layer
      </Button>
      <Stack spacing={1}>
        {Object.keys(assetRiskDatasets).map((key) => (
          <AssetRiskLayerConfig
            dataset={assetRiskDatasets[key]}
            datasetName={key}
          />
        ))}
      </Stack>
    </>
  );
}
