import { PlayCircleOutline } from '@mui/icons-material';
import { Box, Button, CircularProgress, Stack } from '@mui/material';
import { usePropagationNewJobState } from '../contexts/PropagationNewJobStateProvider';
import PropagationConfigField from './PropagationConfigField';
import { usePropagationNewJob } from '../hooks/usePropagationNewJob';
import { useCallback, useState } from 'react';
import LatLngField from './LatLngField';

export default function PropagationConfig(props) {
  const [latLngError, setLatLngError] = useState(false);
  const { parameters, setParameters, isRunning } = usePropagationNewJobState();
  const { runJob } = usePropagationNewJob();

  // console.log('rendering PropagationConfig', parameters);

  const handleChange = useCallback(
    (
      e,
      param,
      min = Number.NEGATIVE_INFINITY,
      max = Number.POSITIVE_INFINITY,
      onBlur = false // Converts input to number when the field is unselected. This ensures that the input is valid and doesn't have a period at the end.
    ) => {
      let newParameters = { ...parameters };
      if (e.target.value === '') {
        newParameters[param] = e.target.value;
        setParameters({ ...newParameters, name: null });
        return;
      }
      let newValue = Number(e.target.value);

      if (!isNaN(newValue)) {
        if (e.target.value?.split('.')[1]?.length > 6) return; // No more than 6 decimal places to make the UI look nicer

        // This is a hacky check to allow user to type a decimal properly - if we turn the input into a number, it gets rid of a decimal point with no numbers after it
        if (
          e.target.value.charAt(e.target.value.length - 1) === '.' &&
          !onBlur
        ) {
          newParameters[param] = e.target.value.toString();
        } else {
          if (newValue < min) {
            newValue = min;
          } else if (newValue > max) {
            newValue = max;
          }
          if (newValue === 0) newValue = '0';
          newParameters[param] = newValue;
        }
        setParameters({ ...newParameters, name: null });
      }
    },
    [parameters, setParameters]
  );

  // const handleLiveChange = (param) => {
  //   let newParameters = { ...parameters };
  //   newParameters[param] = !newParameters[param];
  //   setParameters(newParameters);
  // };

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        runJob(parameters);
        setParameters((prev) => ({
          ...prev,
          name: null,
        }));
      }}
      style={{ flexShrink: 1, padding: '16px', overflow: 'auto' }}
    >
      <Stack spacing={1.5}>
        <LatLngField
          trueLat={parameters.lat}
          trueLng={parameters.lng}
          setTrueLatLng={(lat, lng) =>
            setParameters((prev) => ({
              ...prev,
              lat,
              lng,
            }))
          }
          latLngError={latLngError}
          setLatLngError={setLatLngError}
          disabled={isRunning}
        />
        <PropagationConfigField
          label='Wind Speed'
          value={parameters.windSpeed || ''}
          onChange={(e) => handleChange(e, 'windSpeed', 0)}
          unit='mph'
          min={0}
          // description='Speed of wind in the given direction.'
          // liveToggle={true}
          useLive={parameters.liveMode}
          // onLiveChange={() => handleLiveChange('liveWindSpeed')}
          TextFieldProps={{
            disabled: isRunning,
            onBlur: (e) => handleChange(e, 'windSpeed', 0, 253, true),
          }}
        />
        <PropagationConfigField
          label='Wind Direction'
          value={parameters.windDirection || ''}
          mapHeading={parameters.mapHeading || 0}
          onChange={(e) => handleChange(e, 'windDirection', 0, 360)}
          unit='°'
          // description='Direction wind is coming from in clockwise degrees where 0 is north.'
          // liveToggle={true}
          useLive={parameters.liveMode}
          // onLiveChange={() => handleLiveChange('liveWindDirection')}
          TextFieldProps={{
            disabled: isRunning,
            onBlur: (e) => handleChange(e, 'windDirection', 0, 360, true),
          }}
        />
        <PropagationConfigField
          label='Fuel Moisture Content'
          value={parameters.fmc || ''}
          onChange={(e) => handleChange(e, 'fmc', 0)}
          unit='%'
          // description='Amount of water in fuel available to a fire.'
          // liveToggle={true}
          useLive={parameters.liveMode}
          // onLiveChange={() => handleLiveChange('liveFmc')}
          TextFieldProps={{
            disabled: isRunning,
            onBlur: (e) => handleChange(e, 'fmc', 0, 100, true),
          }}
        />
        {/* </Stack> */}
        {/* </Box> */}
      </Stack>
      <Box mt={2} display='flex' justifyContent='center'>
        <Box position='relative'>
          <Button
            size='large'
            variant='contained'
            startIcon={<PlayCircleOutline />}
            fullWidth
            type='submit'
            disabled={
              !(
                parameters.lat &&
                parameters.lng &&
                ((!parameters.liveMode &&
                  (parameters.windSpeed || parameters.liveWindSpeed) &&
                  (parameters.windDirection || parameters.liveWindDirection) &&
                  (parameters.fmc || parameters.liveFmc)) ||
                  parameters.liveMode)
              ) ||
              isRunning ||
              latLngError
            }
            sx={{ fontWeight: 600 }}
          >
            Run Propagation
          </Button>
          {isRunning && (
            <CircularProgress
              disableShrink
              size={28}
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                marginTop: '-14px',
                marginLeft: '-14px',
              }}
            />
          )}
        </Box>
      </Box>
    </form>
  );
}
