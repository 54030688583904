import {
  Button,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Popover,
  Stack,
} from '@mui/material';
import { useAssetRiskLayersState } from '../../contexts/AssetRiskLayersStateProvider';
import { useState } from 'react';
import { AddCircleOutline } from '@mui/icons-material';
import AssetRiskFilterConfig from './AssetRiskFilterConfig';

export default function AssetRiskFiltersTab(props) {
  const [anchorEl, setAnchorEl] = useState(null);
  const { assetRiskFilters, setAssetRiskFilters } = useAssetRiskLayersState();
  const metrics = [
    'lat',
    'lon',
    'wind_speed',
    'wind_dir',
    'humidity',
    'fmc',
    'fmc2',
    'acres_burned',
    'structures_lost',
    'financial_damages',
  ];

  return (
    <>
      <Popover
        open={anchorEl ? true : false}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <List>
          {metrics.map(
            (metric) =>
              !assetRiskFilters[metric] && (
                <ListItem disablePadding>
                  <ListItemButton
                    onClick={() => {
                      setAnchorEl(null);
                      setAssetRiskFilters((prev) => {
                        const newObject = { ...prev };
                        newObject[metric] = {};
                        newObject[metric].min = '';
                        newObject[metric].max = '';
                        return newObject;
                      });
                    }}
                  >
                    <ListItemText primary={metric} />
                  </ListItemButton>
                </ListItem>
              )
          )}
        </List>
      </Popover>
      <Button
        onClick={(e) => setAnchorEl(e.currentTarget)}
        variant='contained'
        startIcon={<AddCircleOutline />}
        disabled={Object.keys(assetRiskFilters).length >= metrics.length}
      >
        Add Filter
      </Button>
      <Stack spacing={1}>
        {Object.keys(assetRiskFilters).map((key) => (
          <AssetRiskFilterConfig parameter={key} />
        ))}
      </Stack>
    </>
  );
}
