import { createContext, useContext, useEffect, useRef, useState } from 'react';

const PropagationLayerStateContext = createContext();

export default function PropagationLayerStateProvider(props) {
  const [propLayerData, setPropLayerData] = useState(null); // contains bounds and image URLs
  const [activePropIndex, setActivePropIndex] = useState(0); // index of active image
  const [playbackSpeed, setPlaybackSpeed] = useState(50);
  const [isPlaybackRunning, setIsPlaybackRunning] = useState(false);
  const playbackIntervalRef = useRef(null);

  return (
    <PropagationLayerStateContext.Provider
      value={{
        propLayerData,
        setPropLayerData,
        activePropIndex,
        setActivePropIndex,
        playbackSpeed,
        setPlaybackSpeed,
        isPlaybackRunning,
        setIsPlaybackRunning,
        playbackIntervalRef,
      }}
    >
      {props.children}
    </PropagationLayerStateContext.Provider>
  );
}

export const usePropagationLayerState = () =>
  useContext(PropagationLayerStateContext);
