import { styled } from '@mui/material';

const StyledBox = styled('div')({
  background: 'none rgb(255, 255, 255)',
  border: '0px',
  margin: '10px',
  padding: '0px',
  textTransform: 'none',
  appearance: 'none',
  // userSelect: "none",
  borderRadius: '2px',
  boxShadow: 'rgba(0, 0, 0, 0.3) 0px 1px 4px -1px',
  overflow: 'hidden',
  color: 'rgb(0,0,0)',
});

export default function GoogleMapsCustomControl(props) {
  const { BoxProps } = props;
  return <StyledBox {...BoxProps}>{props.children}</StyledBox>;
}
