import {
  Box,
  Divider,
  Grow,
  Paper,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { BookmarkBorder, ContentCopy, Edit } from "@mui/icons-material";
import DirectionPointer from "../DirectionPointer";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import { usePropagationLayerState } from "../contexts/PropagationLayerStateProvider";
import { usePropagationNewJobState } from "../contexts/PropagationNewJobStateProvider";

export default function PropagationHistoryItem(props) {
  const { name, job, selected } = props;
  const [direction, setDirection] = useState(0);
  const theme = useTheme();
  const { setPropLayerData, setActivePropIndex } = usePropagationLayerState();
  const { parameters, setParameters } = usePropagationNewJobState();
  // console.log('rendering PropagationHistoryItem');

  useEffect(() => {
    setDirection(job.parameters.windDirection);
  }, []);

  const handleClick = () => {
    setPropLayerData({
      bounds: job.bounds,
      images: job.images,
      report: job.report,
      timestamp: job.timestamp,
    });
    setActivePropIndex(0);
    setParameters(job.parameters);
  };

  return (
    <Grow in style={{ transformOrigin: "0 0 0" }}>
      <Paper
        onClick={handleClick}
        sx={
          selected
            ? {
                cursor: "pointer",
                m: "-2px !important",
                border: `2px solid ${theme.palette.primary.main}`,
                "&:not(:first-of-type)": {
                  mb: "calc(1em - 2px) !important",
                },
                "&:hover": {
                  border: `2px solid ${theme.palette.primary.dark}`,
                },
              }
            : {
                "&:hover": {
                  cursor: "pointer",
                  m: "-2px",
                  border: "2px solid #fff",
                  "&:not(:first-of-type)": {
                    mb: "calc(1em - 2px) !important",
                  },
                },
              }
        }
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          px={2}
          py={1}
        >
          {/* <Typography variant='h6'>{name}</Typography> */}
          <Typography>{dayjs(job.timestamp).format("h:mmA")}</Typography>
        </Stack>
        <Divider />
        <Stack px={2} py={1} spacing={0.5}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography color="textSecondary">Location:</Typography>
            <Typography>
              {job.parameters.lat}, {job.parameters.lng}
            </Typography>
          </Stack>
          <Stack direction="row" justifyContent="space-between">
            <Typography color="textSecondary">Wind Speed:</Typography>
            <Typography>{job.parameters.windSpeed} mph</Typography>
          </Stack>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography color="textSecondary">Wind Direction:</Typography>
            <Stack
              direction="row"
              flexWrap="wrap"
              alignItems="center"
              spacing={1}
            >
              <DirectionPointer
                direction={
                  (Number(direction - (parameters.mapHeading || 0)) + 180) % 360
                }
              />
              <Typography>{job.parameters.windDirection}°</Typography>
            </Stack>
          </Stack>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography color="textSecondary">
              Fuel Moisture Content:
            </Typography>
            <Typography>{job.parameters.fmc}%</Typography>
          </Stack>
        </Stack>
        <Divider />
        <Stack px={2} py={1} spacing={0.5}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography color="textSecondary">Acres Burned:</Typography>
            <Typography>
              {Number(
                job.report.acresBurned[
                  job.report.acresBurned.length - 1
                ].toFixed(2)
              ).toLocaleString()}
            </Typography>
          </Stack>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography color="textSecondary">Structures Burned:</Typography>
            <Typography>
              {job.report.structsBurned[
                job.report.structsBurned.length - 1
              ].toLocaleString()}
            </Typography>
          </Stack>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography color="textSecondary">Financial Loss:</Typography>
            <Typography>
              {`$${Number(
                job.report.financialLoss[job.report.financialLoss.length - 1]
              ).toLocaleString()}`}
            </Typography>
          </Stack>
        </Stack>
      </Paper>
    </Grow>
  );
}
