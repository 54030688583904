import {
  Box,
  IconButton,
  Popover,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { Attribution } from '@mui/icons-material';
import { useState } from 'react';
import GoogleMapsCustomButton from './map/map-controls/GoogleMapsCustomButton';

export default function AttributionPin(props) {
  const [anchorEl, setAnchorEl] = useState(null);

  return (
    <>
      <GoogleMapsCustomButton
        tooltip='Attribution'
        tooltipPlacement='right'
        ButtonProps={{
          onClick: (e) => setAnchorEl(e.currentTarget),
          size: 'small',
        }}
      >
        <Attribution />
      </GoogleMapsCustomButton>
      <Popover
        open={anchorEl ? true : false}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        // slotProps={{
        //   paper: {
        //     variant: 'outlined',
        //   },
        // }}
      >
        <Stack p={1} gap={1}>
          <Typography
          // sx={{
          //   '& a:link': {
          //     color: '#90caf9',
          //     textDecoration: 'none',
          //   },
          //   '& a:visited': { color: '#ffcdd2' },
          //   '& a:hover': { color: '#e1bee7', textDecoration: 'underline' },
          //   '& a:active': { color: '#e1bee7' },
          // }}
          >
            <a
              href='https://github.com/microsoft/GlobalMLBuildingFootprints'
              target='_blank'
            >
              Building Data Supplied by © Microsoft (ODbL)
            </a>
          </Typography>
          {/* <Typography
            sx={{
              '& a:link': {
                color: '#90caf9',
                textDecoration: 'none',
              },
              '& a:visited': { color: '#ffcdd2' },
              '& a:hover': { color: '#e1bee7', textDecoration: 'underline' },
              '& a:active': { color: '#e1bee7' },
            }}
          >
            <a href='https://www.zillow.com/research/data/' target='_blank'>
              Median Monetary Data by Zillow Research
            </a>
          </Typography> */}
          <Typography
          // sx={{
          //   '& a:link': {
          //     color: '#90caf9',
          //     textDecoration: 'none',
          //   },
          //   '& a:visited': { color: '#ffcdd2' },
          //   '& a:hover': { color: '#e1bee7', textDecoration: 'underline' },
          //   '& a:active': { color: '#e1bee7' },
          // }}
          >
            <a href='https://www.redfin.com/news/data-center/' target='_blank'>
              Housing Market Data by Redfin
            </a>
          </Typography>
        </Stack>
      </Popover>
    </>
  );
}
