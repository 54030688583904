import {
  Box,
  Divider,
  IconButton,
  Paper,
  Slide,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useTheme,
} from '@mui/material';
import PropagationConfig from './PropagationConfig';
import { AddCircleOutline, ChevronRight, History } from '@mui/icons-material';
import PreviousRuns from './PreviousRuns';
import HistoricalFires from './HistoricalFires';
import { useNavigate } from 'react-router-dom';
import AssetRisk from './asset-risk/AssetRisk';

export default function PropagationSidebar(props) {
  const { pageMode } = props;
  const theme = useTheme();
  const navigate = useNavigate();
  // console.log('rendering PropagationSidebar');
  console.log(pageMode);

  return (
    <Box
      display='flex'
      flexDirection='column'
      height='100%'
      width='360px'
      // width='fit-content'
    >
      <Box p={2}>
        <ToggleButtonGroup
          color='primary'
          value={pageMode ? `/propagation/${pageMode}` : '/propagation'}
          exclusive
          onChange={(e) => navigate(e.target.value)}
          fullWidth
          size='small'
        >
          <ToggleButton value='/propagation'>Custom</ToggleButton>
          <ToggleButton value='/propagation/demo'>Demo</ToggleButton>
        </ToggleButtonGroup>
      </Box>
      <Divider />
      <Box position='relative' overflow='hidden' flex={1}>
        <Box
          position='absolute'
          width='100%'
          height='100%'
          zIndex={pageMode !== 'demo' ? 1 : 0}
        >
          <Slide
            in={pageMode !== 'demo'}
            direction='right'
            mountOnEnter
            unmountOnExit
          >
            <Box>
              <Box flexShrink={0}>
                <Stack
                  direction='row'
                  px={3}
                  pt={2}
                  pb={0}
                  alignItems='center'
                  spacing={1}
                >
                  <AddCircleOutline
                    htmlColor={theme.palette.text.truePrimary}
                  />
                  <Typography
                    variant='h5'
                    color={theme.palette.text.truePrimary}
                  >
                    New Run
                  </Typography>
                </Stack>
                <Paper sx={{ m: 3, mt: 1 }}>
                  <PropagationConfig />
                </Paper>
              </Box>
              <Divider />
              <Box overflow='hidden' position='relative' flex={1}>
                <Box height='100%' display='flex' flexDirection='column'>
                  <PreviousRuns />
                </Box>
              </Box>
            </Box>
          </Slide>
        </Box>
        <Box
          position='absolute'
          width='100%'
          height='100%'
          zIndex={pageMode === 'demo' ? 1 : 0}
        >
          <Slide in={pageMode === 'demo'} direction='left'>
            <Box
              height='100%'
              overflow='auto'
              sx={{ scrollbarGutter: 'stable' }}
            >
              <HistoricalFires />
              <Divider sx={{ ml: 2 }} />
              <AssetRisk />
            </Box>
          </Slide>
        </Box>
      </Box>
    </Box>
  );
}
