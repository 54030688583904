import { InfoWindow } from '@vis.gl/react-google-maps';
import { useAssetRiskLayersState } from '../../../contexts/AssetRiskLayersStateProvider';
import { Button, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import { ContentCopy, PlayCircleOutline } from '@mui/icons-material';
import { usePropagationNewJob } from '../../../hooks/usePropagationNewJob';
import { usePropagationNewJobState } from '../../../contexts/PropagationNewJobStateProvider';
import { useNavigate } from 'react-router-dom';

export default function AssetRiskInfoWindow(props) {
  const { selectedAssetRiskPoint, setSelectedAssetRiskPoint } =
    useAssetRiskLayersState();
  const { runJob } = usePropagationNewJob();
  const { setParameters } = usePropagationNewJobState();
  const navigate = useNavigate();

  const handleRunPropagation = () => {
    const parameters = {
      lat: selectedAssetRiskPoint.lat.toFixed(6),
      lng: selectedAssetRiskPoint.lon.toFixed(6),
      windSpeed: selectedAssetRiskPoint.wind_speed,
      liveWindSpeed: false,
      windDirection: selectedAssetRiskPoint.wind_dir,
      liveWindDirection: false,
      fmc: Math.floor(selectedAssetRiskPoint.fmc2 * 100),
      liveFmc: false,
      liveMode: false,
    };
    runJob(parameters);
    setParameters((prev) => ({
      ...prev,
      lat: selectedAssetRiskPoint.lat.toFixed(6),
      lng: selectedAssetRiskPoint.lon.toFixed(6),
      windSpeed: selectedAssetRiskPoint.wind_speed,
      windDirection: selectedAssetRiskPoint.wind_dir,
      fmc: Math.floor(selectedAssetRiskPoint.fmc2 * 100),
    }));
    setSelectedAssetRiskPoint(null);
    navigate('/propagation');
  };

  return (
    selectedAssetRiskPoint && (
      <InfoWindow
        position={{
          lat: selectedAssetRiskPoint.lat,
          lng: selectedAssetRiskPoint.lon,
        }}
        onCloseClick={() => setSelectedAssetRiskPoint(null)}
        headerContent={
          <Tooltip title='Copy Conditions to New Run' placement='right'>
            <IconButton
              color='primary'
              onClick={() => {
                setParameters((prev) => ({
                  ...prev,
                  lat: selectedAssetRiskPoint.lat.toFixed(6),
                  lng: selectedAssetRiskPoint.lon.toFixed(6),
                  windSpeed: selectedAssetRiskPoint.wind_speed,
                  windDirection: selectedAssetRiskPoint.wind_dir,
                  fmc: Math.floor(selectedAssetRiskPoint.fmc2 * 100),
                }));
              }}
            >
              <ContentCopy fontSize='small' />
            </IconButton>
          </Tooltip>
        }
      >
        <Stack>
          {Object.keys(selectedAssetRiskPoint).map((key) => (
            <Stack direction='row' justifyContent='space-between' spacing={1}>
              <Typography fontWeight={500} sx={{ color: '#000000' }}>
                {key}:
              </Typography>
              <Typography sx={{ color: '#000000' }}>
                {key !== 'financial_damages'
                  ? selectedAssetRiskPoint[key]
                  : `$${Number(selectedAssetRiskPoint[key]).toLocaleString()}`}
              </Typography>
            </Stack>
          ))}
          <Button
            startIcon={<PlayCircleOutline />}
            color='primary'
            onClick={handleRunPropagation}
            sx={{ mt: 1 }}
          >
            Run Propagation Here
          </Button>
        </Stack>
      </InfoWindow>
    )
  );
}
