import { createContext, useContext, useState } from "react";

const WindLayerStateContext = createContext();

export default function WindLayerStateProvider(props) {
  const [windManifest, setWindManifest] = useState(null);
  const [activeWindIndex, setActiveWindIndex] = useState(null);
  const [windLayerEnabled, setWindLayerEnabled] = useState(false);
  const [windLayer, setWindLayer] = useState(null);

  return (
    <WindLayerStateContext.Provider
      value={{
        windManifest,
        setWindManifest,
        activeWindIndex,
        setActiveWindIndex,
        windLayerEnabled,
        setWindLayerEnabled,
        windLayer,
        setWindLayer,
      }}
    >
      {props.children}
    </WindLayerStateContext.Provider>
  );
}

export const useWindLayerState = () => useContext(WindLayerStateContext);
