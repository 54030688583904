import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Popover,
} from '@mui/material';
import GoogleMapsCustomButton from './GoogleMapsCustomButton';
import { Layers } from '@mui/icons-material';
import { useState } from 'react';
import { useWindLayerState } from '../../contexts/WindLayerStateProvider';
import { useFirePerimetersLayerState } from '../../contexts/FirePerimetersLayerStateProvider';
import { useFirePerimetersLayer } from '../../hooks/useFirePerimetersLayer';

export default function LayersPopover(props) {
  const [anchorEl, setAnchorEl] = useState(null);
  const { windLayerEnabled, setWindLayerEnabled } = useWindLayerState();
  const { firePerimetersLayerEnabled, setFirePerimetersLayerEnabled } =
    useFirePerimetersLayerState();
  const { updateFirePerimetersLayer } = useFirePerimetersLayer();
  // console.log('rendering LayersPopover');

  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <GoogleMapsCustomButton
        tooltip='Select Layers'
        tooltipPlacement='bottom-end'
        ButtonProps={{ onClick: handleClick }}
      >
        <Layers />
      </GoogleMapsCustomButton>
      <Popover
        open={anchorEl ? true : false}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Box py={1} px={2}>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  size='small'
                  checked={windLayerEnabled}
                  onChange={(e) => setWindLayerEnabled(e.target.checked)}
                />
              }
              label='Wind Speed'
              slotProps={{
                typography: {
                  variant: 'body2',
                },
              }}
            />
          </FormGroup>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  size='small'
                  checked={firePerimetersLayerEnabled}
                  onChange={(e) => {
                    setFirePerimetersLayerEnabled(e.target.checked);
                    updateFirePerimetersLayer(e.target.checked);
                  }}
                />
              }
              label='Fire Perimeters'
              slotProps={{
                typography: {
                  variant: 'body2',
                },
              }}
            />
          </FormGroup>
        </Box>
      </Popover>
    </>
  );
}
