import { inferno } from '../assets/inferno';

export default class NdChaos {
  constructor(ctx, x, y, maxFrames) {
    this.ctx = ctx;
    this.x = x;
    this.y = y;
    this.imageUrls = [];
    this.colormap = inferno; // Predefined colormap
    this.maxFrames = maxFrames;
    // let temp = INFERNO;
    // for (let x = 0; x < temp; x++) {
    //   for (let y = 0; y < 3; y++) {
    //     temp[x][y] = temp[x][y] * 3;
    //   }
    // }
    // console.log(temp);
    return this.init();
  }

  async init() {
    this.data = this.ctx.getImageData(0, 0, this.x, this.y).data;

    // Reshape data and initialize frames
    this.reshapedData = this.reshapeData();
    this.frames = this.createProgressiveFrames(this.maxFrames);

    await this.prepFrames();
    return this;
  }

  reshapeData() {
    const reshaped = new Array(this.y)
      .fill(0)
      .map(() => new Array(this.x).fill(0));
    for (let row = 0; row < this.y; row++) {
      for (let col = 0; col < this.x; col++) {
        const idx = 4 * (row * this.x + col);
        const red = this.data[idx];
        // const green = this.data[idx + 1];
        // const blue = this.data[idx + 2];
        const alpha = this.data[idx + 3];

        if (alpha > 0) {
          reshaped[row][col] = red;
        } else {
          reshaped[row][col] = -1; // Transparent
        }
      }
    }

    return reshaped;
  }

  createProgressiveFrames(maxFrames = 500) {
    // Flatten pixel intensities with coordinates
    const pixels = [];
    for (let row = 0; row < this.y; row++) {
      for (let col = 0; col < this.x; col++) {
        const intensity = this.reshapedData[row][col];
        if (intensity >= 0) {
          // Ignore transparent pixels
          pixels.push({ row, col, intensity });
        }
      }
    }

    // Sort pixels by intensity (hot to cold)
    pixels.sort((a, b) => b.intensity - a.intensity);

    // Unique intensity levels and step granularity
    const uniqueIntensities = [...new Set(pixels.map((p) => p.intensity))].sort(
      (a, b) => b - a
    );

    const frames = [];
    let currentFrame = this.reshapedData.map((row) => row.map(() => -1)); // Fully transparent

    let startIndex = 0;
    for (let i = 0; i < uniqueIntensities.length; i++) {
      // Reveal pixels for the current intensity threshold
      for (const { row, col, intensity } of pixels) {
        if (intensity >= uniqueIntensities[i]) {
          currentFrame[row][col] = intensity; // Reveal pixel
        }
      }

      const scaling = maxFrames / 255; // How many frames each point of intensity represents
      const indexThreshold =
        i < uniqueIntensities.length - 1
          ? maxFrames - Math.round(uniqueIntensities[i] * scaling)
          : maxFrames;
      for (let j = startIndex; j < indexThreshold; j++) {
        frames.push(currentFrame.map((row) => [...row]));
      }
      startIndex = indexThreshold;

      // if (i === 0) {
      //   const framesBefore = Math.round(255 - uniqueIntensities[0]);
      //   for (let i = 0; i <= framesBefore; i++) {
      //     frames.push(currentFrame.map((row) => [...row]));
      //   }
      // } else if (i < uniqueIntensities.length - 1) {
      //   // Get number of frames between intensities and push the frame that many times
      //   const framesBetween = Math.round(
      //     (uniqueIntensities[i] - uniqueIntensities[i + 1]) * framesPerIntensity
      //   );
      //   for (let i = 0; i <= framesBetween; i++) {
      //     frames.push(currentFrame.map((row) => [...row]));
      //   }
      // } else {
      //   frames.push(currentFrame.map((row) => [...row]));
      // }
    }

    // Sort frames by visible pixel count
    return frames
      .map((frame, index) => ({
        frame,
        visiblePixels: frame.flat().filter((p) => p >= 0).length,
      }))
      .sort((a, b) => a.visiblePixels - b.visiblePixels) // Sort by pixel count
      .map((item) => item.frame); // Extract sorted frames
  }

  getImage(index) {
    const frame = this.frames[index];
    const img = new ImageData(this.x, this.y);

    for (let row = 0; row < this.y; row++) {
      for (let col = 0; col < this.x; col++) {
        const intensity = frame[row][col];
        const idx = 4 * (row * this.x + col);

        if (intensity === -1) {
          // Transparent pixel
          img.data[idx] = 0;
          img.data[idx + 1] = 0;
          img.data[idx + 2] = 0;
          img.data[idx + 3] = 0;
        } else {
          // Apply colormap
          const color =
            this.colormap[
              intensity
              // Math.min(
              //   Math.floor((intensity / 256) * this.colormap.length),
              //   this.colormap.length - 1
              // )
            ];
          img.data[idx] = Math.round(color[0] * 255); // Red
          img.data[idx + 1] = Math.round(color[1] * 255); // Green
          img.data[idx + 2] = Math.round(color[2] * 255); // Blue
          img.data[idx + 3] = 255; // Alpha
        }
      }
    }

    return img;
  }

  // Keep track of the previous image and its intensity, then get the difference in intensity between
  async prepFrames() {
    this.imageUrls = []; // Clear previous URLs
    const promises = this.frames.map(
      (frame, i) =>
        new Promise((resolve) => {
          const canvas = document.createElement('canvas');
          canvas.width = this.x;
          canvas.height = this.y;
          const ctx = canvas.getContext('2d');
          const img = this.getImage(i);
          ctx.putImageData(img, 0, 0);
          canvas.toBlob((blob) => {
            this.imageUrls[i] = URL.createObjectURL(blob); // Maintain order
            resolve();
          });
        })
    );

    await Promise.all(promises);
  }
}
