import {
  CrisisAlert,
  ExpandLess,
  ExpandMore,
  FilterAlt,
  Layers,
} from '@mui/icons-material';
import {
  Box,
  Button,
  Collapse,
  Divider,
  IconButton,
  Select,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { useState } from 'react';
import AssetRiskLayersTab from './layers/AssetRiskLayersTab';
import AssetRiskFiltersTab from './AssetRiskFiltersTab';

export default function AssetRisk(props) {
  const [expanded, setExpanded] = useState(false);
  const [selectedTab, setSelectedTab] = useState('layers');
  const theme = useTheme();

  return (
    <Box pl={3} pr={1} py={2}>
      <Stack direction='row' alignItems='center' justifyContent='space-between'>
        <Stack direction='row' alignItems='center' spacing={1}>
          <CrisisAlert htmlColor={theme.palette.text.truePrimary} />
          <Typography variant='h5' color={theme.palette.text.truePrimary}>
            Asset Risk
          </Typography>
        </Stack>
        <IconButton
          onClick={() => setExpanded((prev) => !prev)}
          color='secondary'
        >
          {expanded ? <ExpandLess /> : <ExpandMore />}
        </IconButton>
      </Stack>
      <Collapse in={expanded}>
        <Stack pt={1} spacing={1}>
          <ToggleButtonGroup
            color='standard'
            size='small'
            fullWidth
            exclusive
            onChange={(e) => setSelectedTab(e.target.value)}
            value={selectedTab}
          >
            <ToggleButton value='layers' sx={{ textTransform: 'none' }}>
              <Layers fontSize='small' sx={{ pointerEvents: 'none' }} />
              &nbsp;Layers
            </ToggleButton>
            <ToggleButton value='filters' sx={{ textTransform: 'none' }}>
              <FilterAlt fontSize='small' sx={{ pointerEvents: 'none' }} />
              &nbsp;Filters
            </ToggleButton>
          </ToggleButtonGroup>
          <Divider />
          {selectedTab === 'layers' && <AssetRiskLayersTab />}
          {selectedTab === 'filters' && <AssetRiskFiltersTab />}
        </Stack>
      </Collapse>
    </Box>
  );
}
