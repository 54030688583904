import { FormControl, TextField } from '@mui/material';
import { useMap } from '@vis.gl/react-google-maps';
import { useCallback, useEffect, useRef, useState } from 'react';

export default function LatLngField(props) {
  const {
    trueLat,
    trueLng,
    setTrueLatLng,
    latLngError,
    setLatLngError,
    disabled,
  } = props;
  const [latLngString, setLatLngString] = useState('');
  const wasPasted = useRef(false);
  const map = useMap('propagation-map');

  const handlePaste = () => {
    wasPasted.current = true;
  };

  const handleChange = useCallback(
    (e) => {
      setLatLngString(e.target.value);
      const decimalDegRegex =
        /([-+]?\d{1,2}(?:\.\d+)?),\s*([-+]?\d{1,3}(?:\.\d+)?)/;
      const dmsRegex =
        /(\d{1,2})\D+(\d{1,2})\D+(\d{1,2}(?:\.\d+)?)\D*([NS])\s+(\d{1,3})\D+(\d{1,2})\D+(\d{1,2}(?:\.\d+)?)\D*([EW])/i;
      const ddmRegex =
        /(\d{1,2})\s+(\d{1,2}(?:\.\d+)?)\D*([NS]?),\s*(\d{1,3})\s+(\d{1,2}(?:\.\d+)?)\D*([EW]?)/i;

      let match;
      if ((match = e.target.value.match(ddmRegex))) {
        let lat = parseFloat(match[1]) + match[2] / 60;
        let lng = parseFloat(match[4]) + match[5] / 60;
        lat = match[3].toUpperCase() === 'S' ? -lat : lat;
        lng = match[6].toUpperCase() === 'W' ? -lng : lng;
        if (lat >= -90 && lat <= 90 && lng >= -180 && lng <= 180) {
          setLatLngError(false);
          setTrueLatLng(Number(lat.toFixed(6)), Number(lng.toFixed(6)));
          if (wasPasted.current) {
            map.setCenter({
              lat,
              lng,
            });
          }
        }
      } else if ((match = e.target.value.match(decimalDegRegex))) {
        const lat = parseFloat(match[1]);
        const lng = parseFloat(match[2]);
        if (lat >= -90 && lat <= 90 && lng >= -180 && lng <= 180) {
          setLatLngError(false);
          setTrueLatLng(Number(lat.toFixed(6)), Number(lng.toFixed(6)));
          if (wasPasted.current) {
            map.setCenter({
              lat,
              lng,
            });
          }
        }
      } else if ((match = e.target.value.match(dmsRegex))) {
        let lat = parseFloat(match[1]) + match[2] / 60 + match[3] / 3600;
        let lng = parseFloat(match[5]) + match[6] / 60 + match[7] / 3600;
        lat = match[4].toUpperCase() === 'S' ? -lat : lat;
        lng = match[8].toUpperCase() === 'W' ? -lng : lng;
        if (lat >= -90 && lat <= 90 && lng >= -180 && lng <= 180) {
          setLatLngError(false);
          setTrueLatLng(Number(lat.toFixed(6)), Number(lng.toFixed(6)));
          if (wasPasted.current) {
            map.setCenter({
              lat,
              lng,
            });
          }
        }
      } else {
        setLatLngError(true);
      }
      return;
    },
    [setLatLngString, setTrueLatLng, setLatLngError, map]
  );

  useEffect(() => {
    if (trueLat && trueLng) {
      setLatLngString(`${trueLat}, ${trueLng}`);
    }
  }, [trueLat, trueLng, setLatLngString]);

  return (
    <FormControl fullWidth>
      <TextField
        size='small'
        type='text'
        label='Latitude, Longitude'
        value={latLngString}
        onChange={handleChange}
        onPaste={handlePaste}
        error={latLngError}
        disabled={disabled}
        fullWidth
      />
    </FormControl>
  );
}
