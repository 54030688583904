import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { parse } from '@vanillaes/csv';
import { ScatterplotLayer } from '@deck.gl/layers';
import chroma from 'chroma-js';

const AssetRiskLayersStateContext = createContext();

export default function AssetRiskLayersStateProvider(props) {
  const [assetRiskLayers, setAssetRiskLayers] = useState([]);
  const [assetRiskDatasets, setAssetRiskDatasets] = useState({});
  const [selectedAssetRiskPoint, setSelectedAssetRiskPoint] = useState(null);
  const [assetRiskFilters, setAssetRiskFilters] = useState({});
  const [availableDatasets, setAvailableDatasets] = useState([]);

  const fetchDatasets = useCallback(async () => {
    const response = await fetch(
      'https://staging.propagation.alertwest.com/data/asset_risk/manifest.json'
    );
    const json = await response.json();
    setAvailableDatasets(json.csv_files);
  }, [setAvailableDatasets]);

  useEffect(() => {
    fetchDatasets();
  }, []);

  /*
    Creates a dataset JSON object in the following shape:
    {
      data: [
        {
          $parameter_name: Number,
          ...
        },
        ...
      ],
      maxValues: {
        $parameter_name: Number,
        ...
      },
      minValues: {
        $parameter_name: Number,
        ...
      },
      style: {
        fillColor: {
          parameter: String,
          color: HexColorCode,
          colorScale: String,
          gradient: Boolean,
          colorArray: [
            {
              id: Number,
              color: HexColorCode,
              min: Number
            },
            ...
          ]
        },
        lineColor: {
          parameter: String,
          color: HexColorCode,
          colorScale: String,
          gradient: Boolean,
          colorArray: [
            {
              id: Number,
              color: HexColorCode,
              min: Number
            },
            ...
          ]
        },
        lineWidth: Number,
        radius: {
          parameter: String,
          value: Number,
          scale: Number
        },
        visible: Boolean
      }
    }
  */
  const addDataset = useCallback(
    async (datasetName, datasetUrl) => {
      const response = await fetch(datasetUrl);
      const csvText = await response.text();
      const parsed = parse(csvText, { typed: true });

      const dataProperties = parsed[0]; // first row is property names
      let jsonData = {};
      jsonData.url = datasetUrl;
      jsonData.data = [];
      jsonData.minValues = {};
      jsonData.maxValues = {};

      for (let row = 1; row < parsed.length; row++) {
        let dataObject = {};
        for (let col = 0; col < parsed[row].length; col++) {
          const property = dataProperties[col];
          const propertyValue =
            property !== 'wind_dir'
              ? parsed[row][col]
              : (parsed[row][col] + 180) % 360;
          dataObject[property] = propertyValue;

          if (
            jsonData.minValues[property] === undefined ||
            propertyValue < jsonData.minValues[property]
          ) {
            jsonData.minValues[property] = propertyValue;
          }
          if (
            jsonData.maxValues[property] === undefined ||
            propertyValue > jsonData.maxValues[property]
          ) {
            jsonData.maxValues[property] = propertyValue;
          }
        }
        jsonData.data.push(dataObject);
      }

      jsonData.style = {
        visible: true,
        fillColor: {
          parameter: '',
          color: '#42a5f5',
          colorScale: 'Quantize',
          gradient: false,
          reverse: false,
          colorPreset: 'OrRd',
          colorArray: [
            { id: 1, color: '#fff7ec' },
            { id: 2, color: '#fddcaf' },
            { id: 3, color: '#fdb27b' },
            { id: 4, color: '#f26d4b' },
            { id: 5, color: '#c91d13' },
            { id: 6, color: '#7f0000' },
          ],
        },
        lineColor: {
          parameter: '',
          color: '#212121',
          colorScale: 'Quantize',
          gradient: false,
          reverse: false,
          colorPreset: 'OrRd',
          colorArray: [
            { id: 1, color: '#fff7ec' },
            { id: 2, color: '#fddcaf' },
            { id: 3, color: '#fdb27b' },
            { id: 4, color: '#f26d4b' },
            { id: 5, color: '#c91d13' },
            { id: 6, color: '#7f0000' },
          ],
        },
        lineWidth: 5,
        radius: {
          parameter: '',
          value: 1,
          scale: 100,
        },
      };

      jsonData.update = (path, value) => {
        setAssetRiskDatasets((prev) => {
          const newObject = { ...prev };
          const dataset = newObject[datasetName];
          const keys = path.split('.');
          let current = dataset;

          for (let i = 0; i < keys.length - 1; i++) {
            const key = keys[i];
            if (current[key] === null || typeof current[key] !== 'object') {
              current[key] = {};
            }
            current = current[key];
          }

          current[keys[keys.length - 1]] = value;
          return newObject;
        });
      };

      setAssetRiskDatasets((prev) => {
        const newObject = { ...prev };
        newObject[datasetName] = jsonData;
        return newObject;
      });
    },
    [setAssetRiskDatasets]
  );

  useEffect(() => {
    setAssetRiskLayers(
      Object.keys(assetRiskDatasets).map((key) => {
        const dataset = assetRiskDatasets[key];
        const data = dataset.data.filter((d) =>
          Object.keys(assetRiskFilters).length > 0
            ? Object.keys(assetRiskFilters).every((key) => {
                if (
                  assetRiskFilters[key].min !== '' &&
                  d[key] < assetRiskFilters[key].min
                ) {
                  return false;
                }
                if (
                  assetRiskFilters[key].max !== '' &&
                  d[key] > assetRiskFilters[key].max
                ) {
                  return false;
                }
                return true;
              })
            : true
        );

        let fillColor;
        let lineColor;
        if (dataset.style.fillColor.parameter === '') {
          fillColor = chroma(dataset.style.fillColor.color).rgb();
        } else {
          if (dataset.style.fillColor.gradient) {
            fillColor = chroma
              .scale(
                dataset.style.fillColor.colorArray.map((object) => object.color)
              )
              .domain([
                dataset.minValues[dataset.style.fillColor.parameter],
                ...dataset.style.fillColor.colorArray
                  .filter((object) => object.min !== null)
                  .map((object) => object.min),
              ]);
          } else {
            fillColor = (value) => {
              const colorArray = dataset.style.fillColor.colorArray;
              let fillColorValue = colorArray[0].color;
              for (let i = 1; i < colorArray.length; i++) {
                if (
                  value !== 0
                    ? value >= colorArray[i].min
                    : value === colorArray[i].max
                ) {
                  fillColorValue = colorArray[i].color;
                }
              }
              return chroma(fillColorValue);
            };
          }
        }

        if (dataset.style.lineColor.parameter === '') {
          lineColor = chroma(dataset.style.lineColor.color).rgb();
        } else {
          if (dataset.style.lineColor.gradient) {
            lineColor = chroma
              .scale(
                dataset.style.lineColor.colorArray.map((object) => object.color)
              )
              .domain([
                dataset.minValues[dataset.style.lineColor.parameter],
                ...dataset.style.lineColor.colorArray
                  .filter((object) => object.min !== null)
                  .map((object) => object.min),
              ]);
          } else {
            lineColor = (value) => {
              const colorArray = dataset.style.lineColor.colorArray;
              let lineColorValue = colorArray[0].color;
              for (let i = 1; i < colorArray.length; i++) {
                if (value >= colorArray[i].min) {
                  lineColorValue = colorArray[i].color;
                }
              }
              return chroma(lineColorValue);
            };
          }
        }

        return new ScatterplotLayer({
          id: `asset-risk-${key}`,
          data,
          stroked: true,
          visible: dataset.style.visible,
          getPosition: (d) => [d.lon, d.lat],
          getFillColor: (d) => {
            const parameter = dataset.style.fillColor.parameter;
            if (parameter !== '') {
              return fillColor(d[parameter]).rgb();
            } else {
              return fillColor;
            }
          },
          getLineColor: (d) => {
            const parameter = dataset.style.lineColor.parameter;
            if (parameter !== '') {
              return lineColor(d[parameter]).rgb();
            } else {
              return lineColor;
            }
          },
          getLineWidth: dataset.style.lineWidth,
          getRadius: (d) => {
            const parameter = dataset.style.radius.parameter;
            if (parameter !== '') {
              return (
                ((d[parameter] - dataset.minValues[parameter]) /
                  (dataset.maxValues[parameter] -
                    dataset.minValues[parameter])) *
                10
              );
            } else {
              return dataset.style.radius.value;
            }
          },
          radiusScale: dataset.style.radius.scale,
          pickable: true,
          autoHighlight: true,
          highlightColor: [255, 255, 255, 150],
          updateTriggers: {
            getFillColor: [assetRiskDatasets],
            getLineColor: [assetRiskDatasets],
            getLineWidth: [assetRiskDatasets],
            getRadius: [assetRiskDatasets],
            radiusScale: [assetRiskDatasets],
          },
          onClick: (info) => {
            setSelectedAssetRiskPoint(info.object);
          },
        });
      })
    );
  }, [assetRiskDatasets, assetRiskFilters]);

  return (
    <AssetRiskLayersStateContext.Provider
      value={{
        assetRiskLayers,
        assetRiskDatasets,
        setAssetRiskDatasets,
        addDataset,
        selectedAssetRiskPoint,
        setSelectedAssetRiskPoint,
        assetRiskFilters,
        setAssetRiskFilters,
        availableDatasets,
      }}
    >
      {props.children}
    </AssetRiskLayersStateContext.Provider>
  );
}

export const useAssetRiskLayersState = () =>
  useContext(AssetRiskLayersStateContext);
