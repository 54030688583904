import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import AssetRiskColorPaletteSelect from './AssetRiskColorPaletteSelect';

export default function AssetRiskColorScaleConfig(props) {
  const {
    colorScale,
    updateColorScale,
    colorArray,
    updateColorArray,
    colorPreset,
    updateColorPreset,
    gradient,
    toggleGradient,
    reverse,
    toggleReverse,
  } = props;

  return (
    <>
      <FormControl>
        <InputLabel id='color-scale-select-label'>Color Scale</InputLabel>
        <Select
          value={colorScale}
          onChange={(e) => updateColorScale(e.target.value)}
          size='small'
          label='Color Scale'
          labelId='color-scale-select-label'
        >
          <MenuItem value='Quantize'>Quantize</MenuItem>
          <MenuItem value='Quantile'>Quantile</MenuItem>
          {/* <MenuItem value='Natural'>Natural</MenuItem> */}
          <MenuItem value='Standard Deviation'>Standard Deviation</MenuItem>
        </Select>
      </FormControl>
      <AssetRiskColorPaletteSelect
        colorArray={colorArray}
        updateColorArray={updateColorArray}
        colorPreset={colorPreset}
        updateColorPreset={updateColorPreset}
        gradient={gradient}
        toggleGradient={toggleGradient}
        reverse={reverse}
        toggleReverse={toggleReverse}
      />
    </>
  );
}
