import { Box, Stack, Typography } from '@mui/material';
import GoogleMapsCustomControl from '../../map-controls/GoogleMapsCustomControl';
import dayjs from 'dayjs';

export default function FirePerimetersTooltip(props) {
  const { selectedFirePerimeterData } = props;

  const properties = selectedFirePerimeterData?.object?.properties;

  return (
    <GoogleMapsCustomControl BoxProps={{ sx: { p: 1 } }}>
      <Stack>
        {properties?.poly_IncidentName && (
          <Typography variant='h6'>{properties?.poly_IncidentName}</Typography>
        )}
        {properties?.attr_FireDiscoveryDateTime && (
          <Stack
            direction='row'
            justifyContent='space-between'
            alignItems='center'
            gap={2}
          >
            <Typography variant='subtitle2'>Discovered:</Typography>
            <Typography variant='caption'>
              {dayjs(properties?.attr_FireDiscoveryDateTime).format(
                'MMM D, YYYY h:mm A Z'
              )}
            </Typography>
          </Stack>
        )}
        {properties?.attr_ContainmentDateTime && (
          <Stack
            direction='row'
            justifyContent='space-between'
            alignItems='center'
            gap={2}
          >
            <Typography variant='subtitle2'>Contained:</Typography>
            <Typography variant='caption'>
              {dayjs(properties?.attr_ContainmentDateTime).format(
                'MMM D, YYYY h:mm A Z'
              )}
            </Typography>
          </Stack>
        )}
        {properties?.poly_DateCurrent && (
          <Stack
            direction='row'
            justifyContent='space-between'
            alignItems='center'
            gap={2}
          >
            <Typography variant='subtitle2'>Last Updated:</Typography>
            <Typography variant='caption'>
              {dayjs(properties?.poly_DateCurrent).format(
                'MMM D, YYYY h:mm A Z'
              )}
            </Typography>
          </Stack>
        )}
        {properties?.poly_GISAcres && (
          <Stack
            direction='row'
            justifyContent='space-between'
            alignItems='center'
            gap={2}
          >
            <Typography variant='subtitle2'>Acres Burned:</Typography>
            <Typography variant='caption'>
              {properties?.poly_GISAcres.toFixed(2)}
            </Typography>
          </Stack>
        )}
        {/* {properties?.poly_IRWINID && (
          <Stack
            direction='row'
            justifyContent='space-between'
            alignItems='center'
            gap={2}
          >
            <Typography variant='subtitle2'>IRWIN ID:</Typography>
            <Typography variant='caption'>
              {properties?.poly_IRWINID}
            </Typography>
          </Stack>
        )} */}
        {properties?.poly_Source && (
          <Stack
            direction='row'
            justifyContent='space-between'
            alignItems='center'
            gap={2}
          >
            <Typography variant='subtitle2'>Source:</Typography>
            <Typography variant='caption'>{properties?.poly_Source}</Typography>
          </Stack>
        )}
      </Stack>
    </GoogleMapsCustomControl>
  );
}
