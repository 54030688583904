import { createContext, useContext, useState } from "react";

const PropagationNewJobStateContext = createContext();

export default function PropagationNewJobStateProvider(props) {
  const [parameters, setParameters] = useState({
    lat: null,
    lng: null,
    windSpeed: null,
    liveWindSpeed: false,
    windDirection: null,
    liveWindDirection: false,
    fmc: null,
    liveFmc: false,
    liveMode: false,
  });
  const [isRunning, setIsRunning] = useState(false);

  return (
    <PropagationNewJobStateContext.Provider
      value={{ parameters, setParameters, isRunning, setIsRunning }}
    >
      {props.children}
    </PropagationNewJobStateContext.Provider>
  );
}

export const usePropagationNewJobState = () =>
  useContext(PropagationNewJobStateContext);
