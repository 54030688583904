import { GeoJsonLayer } from '@deck.gl/layers';
import { useMap } from '@vis.gl/react-google-maps';
import { useFirePerimetersLayerState } from '../contexts/FirePerimetersLayerStateProvider';
import { useCallback, useRef } from 'react';

export const useFirePerimetersLayer = () => {
  const {
    setFirePerimeterLayerIsHovered,
    firePerimetersTimeRange,
    setFirePerimetersLayer,
    setSelectedFirePerimeterData,
  } = useFirePerimetersLayerState();
  const map = useMap('propagation-map');
  const hoverTimerRef = useRef(null);
  const mousePositionRef = useRef(null);

  // const fetchLayer = useCallback(async (url) => {
  //   const response = await fetch(url);
  //   const data = await response.json();

  //   console.log('before', data);
  //   const rewoundLayer = {
  //     type: 'FeatureCollection',
  //     features: data.features.map((feature) => rewind(feature)),
  //   };
  //   console.log('after', rewoundLayer, testvar);

  //   return rewoundLayer;
  // }, []);

  const updateFirePerimetersLayer = useCallback(
    (visible = true) => {
      const bounds = map.getBounds();
      const ne = bounds.getNorthEast();
      const sw = bounds.getSouthWest();
      const geometry = encodeURIComponent(
        JSON.stringify({
          xmin: sw.lng(),
          ymin: sw.lat(),
          xmax: ne.lng(),
          ymax: ne.lat(),
          spatialReference: { wkid: 4326 },
        })
      );

      setFirePerimetersLayer(
        new GeoJsonLayer({
          id: 'fire-perimeters-layer',
          data: `https://services3.arcgis.com/T4QMspbfLg3qTGWY/ArcGIS/rest/services/
						WFIGS_Interagency_Perimeters/FeatureServer/0/
						query?where=EXTRACT%28YEAR+FROM+attr_FireDiscoveryDateTime%29+BETWEEN+${firePerimetersTimeRange[0].$y}+AND+${firePerimetersTimeRange[1].$y}
						&fullText=
						&objectIds=
						&geometry=${geometry}
						&geometryType=esriGeometryEnvelope
						&inSR=4326
						&spatialRel=esriSpatialRelIntersects
						&resultType=none
						&distance=0.0
						&units=esriSRUnit_Meter
						&relationParam=
						&returnGeodetic=false
						&outFields=
							poly_IncidentName
							%2Cpoly_GISAcres
							%2Cpoly_IRWINID
							%2Cpoly_Source
							%2Cpoly_DateCurrent
							%2Cattr_ContainmentDateTime
							%2Cattr_FireDiscoveryDateTime
						&returnGeometry=true
						&returnCentroid=false
						&returnEnvelope=false
						&featureEncoding=esriDefault
						&multipatchOption=xyFootprint
						&maxAllowableOffset=
						&geometryPrecision=
						&outSR=4326
						&defaultSR=
						&datumTransformation=
						&applyVCSProjection=false
						&returnIdsOnly=false
						&returnUniqueIdsOnly=false
						&returnCountOnly=false
						&returnExtentOnly=false
						&returnQueryGeometry=false
						&returnDistinctValues=false
						&cacheHint=false
						&collation=
						&orderByFields=
						&groupByFieldsForStatistics=
						&outStatistics=
						&having=
						&resultOffset=
						&resultRecordCount=
						&returnZ=false
						&returnM=false
						&returnTrueCurves=false
						&returnExceededLimitFeatures=true
						&quantizationParameters=
						&sqlFormat=none
						&f=pgeojson
						&token=`,
          stroked: true,
          autoHighlight: true,
          getFillColor: [255, 0, 0, 50],
          highlightColor: [255, 255, 0, 25],
          getLineWidth: 20,
          pickable: true,
          onHover: (e) => {
            // Update tooltip if there's an object, otherwise kill everything
            if (e.object) {
              setSelectedFirePerimeterData(e);
            } else {
              setFirePerimeterLayerIsHovered(false);
              clearTimeout(hoverTimerRef.current);
              return;
            }

            // If the user has been hovering the same spot for 3 seconds, show tooltip
            if (
              mousePositionRef?.current?.x !== e.x ||
              mousePositionRef?.current?.y !== e.y
            ) {
              clearTimeout(hoverTimerRef.current);
              hoverTimerRef.current = null;
              setFirePerimeterLayerIsHovered(false);
            }
            mousePositionRef.current = { x: e.x, y: e.y };
            if (!hoverTimerRef.current) {
              hoverTimerRef.current = setTimeout(() => {
                setFirePerimeterLayerIsHovered(true);
              }, [2000]);
            }
          },
          visible: visible,
        })
      );
    },
    [
      firePerimetersTimeRange,
      setFirePerimetersLayer,
      map,
      setSelectedFirePerimeterData,
    ]
  );

  return { updateFirePerimetersLayer };
};
