import { SlowMotionVideo } from '@mui/icons-material';
import {
  IconButton,
  Popover,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
} from '@mui/material';
import { useState } from 'react';
import { usePropagationLayer } from '../../hooks/usePropagationLayer';

export default function PlaybackSpeed(props) {
  const [anchorEl, setAnchorEl] = useState(null);
  const { playbackSpeed, updatePlaybackSpeed } = usePropagationLayer();

  return (
    <>
      <Tooltip title='Playback Speed'>
        <IconButton
          size='small'
          color='inherit'
          onClick={(e) => setAnchorEl(e.currentTarget)}
        >
          <SlowMotionVideo />
        </IconButton>
      </Tooltip>
      <Popover
        open={anchorEl ? true : false}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      >
        <ToggleButtonGroup
          color='primary'
          value={playbackSpeed}
          exclusive
          onChange={(e) => updatePlaybackSpeed(Number(e.target.value))}
          orientation='vertical'
        >
          <ToggleButton value={50} sx={{ textTransform: 'none' }}>
            Fast
          </ToggleButton>
          <ToggleButton value={100} sx={{ textTransform: 'none' }}>
            Medium
          </ToggleButton>
          <ToggleButton value={200} sx={{ textTransform: 'none' }}>
            Slow
          </ToggleButton>
        </ToggleButtonGroup>
      </Popover>
    </>
  );
}
