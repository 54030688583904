import { ChevronRight, History } from '@mui/icons-material';
import {
  Box,
  IconButton,
  Slide,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import PropagationHistoryStack from './PropagationHistoryStack';
import { useRef } from 'react';

export default function PreviousRuns(props) {
  const theme = useTheme();

  return (
    <>
      <Stack
        direction='row'
        px={3}
        pt={2}
        pb={0}
        alignItems='center'
        spacing={1}
      >
        <History htmlColor={theme.palette.text.truePrimary} />
        <Typography variant='h5' color={theme.palette.text.truePrimary}>
          Previous Runs
        </Typography>
      </Stack>
      <Box flex={1} overflow='auto' p={3} pt={1}>
        <PropagationHistoryStack />
      </Box>
    </>
  );
}
