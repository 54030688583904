import { memo, useCallback, useEffect, useRef } from 'react';
import BaseMap from './BaseMap';
import {
  AdvancedMarker,
  ControlPosition,
  Pin,
  useMap,
} from '@vis.gl/react-google-maps';
import { Box, createTheme, Fade, ThemeProvider } from '@mui/material';
import { usePropagationNewJobState } from '../contexts/PropagationNewJobStateProvider';
import { usePropagationLayer } from '../hooks/usePropagationLayer';
import { useWindLayerState } from '../contexts/WindLayerStateProvider';
import { useWindLayer } from '../hooks/useWindLayer';
import MapControls from './map-controls/MapControls';
import { useFirePerimetersLayerState } from '../contexts/FirePerimetersLayerStateProvider';
import FirePerimetersTooltip from './layers/fire-perimeters-layer/FirePerimetersTooltip';
import { useAssetRiskLayersState } from '../contexts/AssetRiskLayersStateProvider';
import AssetRiskInfoWindow from './layers/asset-risk-layer/AssetRiskInfoWindow';
import { useFirePerimetersLayer } from '../hooks/useFirePerimetersLayer';

const theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#304ffe',
    },
    secondary: {
      main: '#fdb01e',
    },
    contrastThreshold: 4.5,
  },
  typography: {
    fontFamily: 'Roboto, Arial, sans-serif',
  },
});

export default memo(function PropagationMap(props) {
  const map = useMap('propagation-map');
  const deckRef = useRef(null);
  const { parameters, setParameters } = usePropagationNewJobState();
  const { propLayer, boundsLayer, propBounds } = usePropagationLayer();
  const { windManifest, activeWindIndex, windLayer, windLayerEnabled } =
    useWindLayerState();
  const { getWindManifest, updateWindLayer } = useWindLayer();
  const {
    firePerimeterLayerIsHovered,
    selectedFirePerimeterData,
    firePerimetersLayer,
    firePerimetersLayerEnabled,
  } = useFirePerimetersLayerState();
  const { updateFirePerimetersLayer } = useFirePerimetersLayer();
  const { isRunning } = usePropagationNewJobState();
  const { assetRiskLayers } = useAssetRiskLayersState();
  // const [testLayer, setTestLayer] = useState(null);
  // console.log('rendering PropagationMap');

  useEffect(() => {
    if (!windManifest) getWindManifest();
  }, []);

  useEffect(() => {
    updateWindLayer();
  }, [windManifest, activeWindIndex, windLayerEnabled]);

  useEffect(() => {
    deckRef.current.setProps({
      layers: [
        windLayer,
        firePerimetersLayer,
        ...assetRiskLayers,
        propLayer,
        boundsLayer,
      ],
    });
  }, [propLayer, boundsLayer, windLayer, firePerimetersLayer, assetRiskLayers]);

  useEffect(() => {
    if (propBounds) {
      map.setCenter({ lat: parameters?.lat - 0.01, lng: parameters?.lng });
      map.setZoom(12);
    }
  }, [propBounds]);

  const handleMapClick = useCallback(
    (e) => {
      if (e?.layer?.id.includes('asset-risk')) return;
      const lat = e.coordinate[1].toFixed(6);
      const lng = e.coordinate[0].toFixed(6);
      // const lat = Number(e.detail.latLng.lat.toFixed(6));
      // const lng = Number(e.detail.latLng.lng.toFixed(6));
      let closest = null;
      let closestDistance = 0;
      windManifest[activeWindIndex].data.forEach((point) => {
        let distance = Math.sqrt(
          Math.pow(point[1] - lng, 2) + Math.pow(point[0] - lat, 2)
        );
        if (!closest || distance < closestDistance) {
          closest = point;
          closestDistance = distance;
        }
      });
      setParameters((prev) => ({
        ...prev,
        windSpeed: closest[2],
        windDirection: (closest[3] + 180) % 360,
        lat,
        lng,
      }));
    },
    [windManifest, setParameters]
  );

  const handleHeadingChanged = (e) => {
    setParameters((prev) => ({
      ...prev,
      mapHeading: e.detail.heading,
    }));
  };

  return (
    <ThemeProvider theme={theme}>
      {/* <ScopedCssBaseline sx={{ height: '100%' }}> */}
      <BaseMap
        MapProps={{
          defaultBounds: {
            north: 42,
            south: 32,
            west: -124,
            east: -114,
          },
          streetViewControl: false,
          mapId: 'bugged', // mapId needs to be present but fake to prevent WebGL errors
          mapTypeId: 'terrain',
          // onClick: handleMapClick,
          onHeadingChanged: handleHeadingChanged,
          onIdle: () => {
            updateWindLayer();
            if (firePerimetersLayerEnabled) updateFirePerimetersLayer();
          },
          options: {
            fullscreenControlOptions: {
              position: ControlPosition.TOP_RIGHT,
            },
          },
          style: {
            overflow: 'hidden',
          },
          // options: {
          // 	clickableIcons: false,
          // 	styles: [
          // 		{
          // 			featureType: 'poi',
          // 			elementType: 'all',
          // 			stylers: [{ visibility: 'off' }],
          // 		},
          // 	],
          // },
        }}
        OverlayProps={{ onClick: handleMapClick, interleaved: true }}
        mapId='propagation-map'
        ref={deckRef}
      >
        <MapControls />
        {parameters.lat && parameters.lng && (
          <AdvancedMarker
            position={{
              lat: Number(parameters.lat),
              lng: Number(parameters.lng),
            }}
          >
            <Pin background='#fdb01e' borderColor='#000' glyphColor='#000' />
          </AdvancedMarker>
        )}
        {map && (
          <Fade in={firePerimeterLayerIsHovered}>
            <Box
              position='absolute'
              left={selectedFirePerimeterData?.x}
              top={selectedFirePerimeterData?.y}
              width='max-content'
              sx={{
                transform: `translate(${
                  selectedFirePerimeterData?.x > map.getDiv().offsetWidth - 300
                    ? '-100%'
                    : 0
                }, ${
                  selectedFirePerimeterData?.y > map.getDiv().offsetHeight - 150
                    ? '-100%'
                    : 0
                })`,
              }}
            >
              <FirePerimetersTooltip
                selectedFirePerimeterData={selectedFirePerimeterData}
              />
            </Box>
          </Fade>
        )}
        <AssetRiskInfoWindow />
        <Box
          display={
            !windManifest || !windManifest[activeWindIndex]?.data || isRunning
              ? 'block'
              : 'none'
          }
          position='absolute'
          top={0}
          height='100%'
          width='100%'
          sx={{ backgroundColor: 'rgba(0,0,0,0.5)' }}
        />
      </BaseMap>
      {/* </ScopedCssBaseline> */}
    </ThemeProvider>
  );
});
