import { ExpandLess, ExpandMore, Whatshot } from '@mui/icons-material';
import {
  Box,
  Collapse,
  IconButton,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import PropagationScenarioItem from './PropagationScenarioItem';
import { usePropagationHistory } from '../contexts/PropagationHistoryProvider';
import { useState } from 'react';
// import { scenarios } from "../../assets/scenarios";

export default function HistoricalFires(props) {
  const [expanded, setExpanded] = useState(false);
  const { scenarios } = usePropagationHistory();
  const theme = useTheme();

  return (
    <Box pl={3} pr={1} py={2}>
      <Stack direction='row' alignItems='center' justifyContent='space-between'>
        <Stack direction='row' alignItems='center' spacing={1}>
          <Whatshot htmlColor={theme.palette.text.truePrimary} />
          <Typography variant='h5' color={theme.palette.text.truePrimary}>
            Scenarios
          </Typography>
        </Stack>
        <IconButton
          onClick={() => setExpanded((prev) => !prev)}
          color='secondary'
        >
          {expanded ? <ExpandLess /> : <ExpandMore />}
        </IconButton>
      </Stack>
      <Collapse in={expanded}>
        <Box pt={1}>
          <Stack spacing={2} direction='column-reverse'>
            {scenarios.map((scenario, index) => (
              <PropagationScenarioItem
                key={scenario.parameters.name}
                scenario={scenario}
                scenarioIndex={index}
              />
            ))}
          </Stack>
        </Box>
      </Collapse>
    </Box>
  );
}
