import { ArrowUpward, Navigation } from "@mui/icons-material";
import { Box, useTheme } from "@mui/material";
import { useCallback, useEffect, useRef, useState } from "react";
import debounce from "../functions/debounce";

export default function DirectionPointer(props) {
  const { ArrowProps, ArrowSx, BoxProps, BoxSx, direction } = props;
  const [normalizedDirection, setNormalizedDirection] = useState(direction);
  const [duration, setDuration] = useState(0);
  const theme = useTheme();
  // console.log('rendering DirectionPointer');

  useEffect(() => {
    // console.log("DirectionPointer direction", direction);
    shortestPathRotation(direction % 360);
  }, [direction]);

  const shortestPathRotation = useCallback(
    debounce(function (target) {
      const current = normalizedDirection % 360;

      const delta = target - current;
      const shortestDelta =
        delta > 180 ? delta - 360 : delta < -180 ? delta + 360 : delta;

      // console.log(
      // 	`current: ${current}\ntarget: ${target}\ndelta: ${delta}\nshortestDelta: ${shortestDelta}`
      // );
      setNormalizedDirection(current + shortestDelta);
      setDuration(Math.min(Math.abs(shortestDelta) * 0.01, 0.8));
    }, 300),
    [normalizedDirection, setNormalizedDirection, setDuration]
  );

  return (
    <Box
      overflow="hidden"
      sx={{
        flex: "0 0 auto",
        borderRadius: 10,
        border: `1px solid ${theme.palette.text.secondary}`,
        aspectRatio: 1,
        ...BoxSx,
      }}
      {...BoxProps}
    >
      <ArrowUpward
        color="primary"
        sx={{
          // animation: 'spin 5s infinite linear',
          transition: `transform ${duration}s ease-in-out`,
          transform: `rotate(${normalizedDirection}deg)`,
          // stroke: theme.palette.text.secondary,
          ...ArrowSx,
        }}
        {...ArrowProps}
      />
    </Box>
  );
}
