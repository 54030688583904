import {
  Divider,
  Fade,
  Grow,
  IconButton,
  Paper,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import {
  ContentCopy,
  ExpandLess,
  ExpandMore,
  PlayCircleOutline,
} from '@mui/icons-material';
import DirectionPointer from '../DirectionPointer';
import { usePropagationNewJobState } from '../contexts/PropagationNewJobStateProvider';
import { useCallback, useState } from 'react';
import { useMap } from '@vis.gl/react-google-maps';
import { usePropagationNewJob } from '../hooks/usePropagationNewJob';
import { usePropagationLayerState } from '../contexts/PropagationLayerStateProvider';
import { useFirePerimetersLayerState } from '../contexts/FirePerimetersLayerStateProvider';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';

export default function PropagationScenarioItem(props) {
  const { scenario, scenarioIndex } = props;
  const [expanded, setExpanded] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const { setPropLayerData, setActivePropIndex } = usePropagationLayerState();
  const { parameters, setParameters, isRunning } = usePropagationNewJobState();
  const { runJob } = usePropagationNewJob();
  const {
    firePerimetersTimeRange,
    setFirePerimetersTimeRange,
    setFirePerimetersLayerEnabled,
  } = useFirePerimetersLayerState();
  const navigate = useNavigate();

  const map = useMap('propagation-map');
  const theme = useTheme();

  const copyToNewRun = useCallback(() => {
    // Convert wind direction from meteorological to mathematical
    // and adjust for the 270° offset in the scenarios
    const windDirection = (scenario.parameters.windDirection + 90) % 360;

    setParameters({
      name: scenario.parameters.name,
      lat: scenario.parameters.lat,
      lng: scenario.parameters.lng,
      windSpeed: scenario.parameters.windSpeed,
      windDirection: windDirection,
      fmc: Number(Math.floor(scenario.parameters.fmc * 100)), // Convert from decimal to percentage
      liveMode: false,
    });

    map.setCenter({
      lat: scenario.parameters.lat,
      lng: scenario.parameters.lng,
    });
    map.setZoom(11);
  }, [scenario?.parameters, map]);

  const handleExpandClick = (e) => {
    e.stopPropagation(); // Prevent the parent onClick from firing
    setExpanded(!expanded);
  };

  const handleSelect = useCallback(() => {
    setExpanded(true);
    setPropLayerData({
      bounds: scenario.bounds,
      images: scenario.images,
      report: scenario.report,
      timestamp: scenario.timestamp,
    });
    setActivePropIndex(0);
    setParameters({
      name: scenario.parameters.name,
      lat: scenario.parameters.lat,
      lng: scenario.parameters.lng,
      windSpeed: scenario.parameters.windSpeed,
      windDirection: (scenario.parameters.windDirection + 90) % 360,
      fmc: Math.floor(scenario.parameters.fmc * 100),
    });
    map.fitBounds({
      north: scenario.bounds[3],
      south: scenario.bounds[1],
      west: scenario.bounds[0],
      east: scenario.bounds[2],
    });
    loadFirePerimeter();
  }, [
    setExpanded,
    scenario,
    setPropLayerData,
    setActivePropIndex,
    setParameters,
    loadFirePerimeter,
  ]);

  const loadFirePerimeter = useCallback(() => {
    setFirePerimetersLayerEnabled(true);
    const scenarioYear = dayjs().year(scenario?.parameters?.year);
    setFirePerimetersTimeRange([scenarioYear, scenarioYear]);
  }, [
    setFirePerimetersLayerEnabled,
    firePerimetersTimeRange,
    setFirePerimetersTimeRange,
    scenario,
  ]);

  return (
    <Grow in style={{ transformOrigin: '0 0 0' }}>
      <Paper
        onClick={
          scenario.report && scenario.parameters.name !== parameters?.name
            ? handleSelect
            : null
        }
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        sx={
          scenario.report &&
          (scenario.parameters.name !== parameters?.name
            ? {
                cursor: 'pointer',
                '&:hover': {
                  m: '-2px',
                  border: '2px solid #fff',
                  '&:not(:first-of-type)': {
                    mb: 'calc(1em - 2px) !important',
                  },
                },
              }
            : {
                m: '-2px !important',
                border: `2px solid ${theme.palette.primary.main}`,
                '&:not(:first-of-type)': {
                  mb: 'calc(1em - 2px) !important',
                },
              })
        }
      >
        <Stack
          direction='row'
          justifyContent='space-between'
          alignItems='center'
          p={1}
          pl={2}
        >
          <Typography fontWeight={500}>{scenario.parameters.name}</Typography>
          <Stack direction='row' alignItems='center'>
            {!scenario.report && (
              <Fade in={isHovered}>
                <Tooltip title='Run Historical Propagation'>
                  <IconButton
                    color='primary'
                    size='small'
                    onClick={() => {
                      copyToNewRun();
                      loadFirePerimeter();
                      runJob(scenario.parameters, scenarioIndex);
                      setExpanded(true);
                    }}
                    disabled={isRunning}
                  >
                    <PlayCircleOutline />
                  </IconButton>
                </Tooltip>
              </Fade>
            )}
            <Fade in={isHovered}>
              <Tooltip title='Copy Conditions to New Run'>
                <IconButton
                  color='primary'
                  onClick={(e) => {
                    e.stopPropagation();
                    copyToNewRun();
                    navigate('/propagation');
                  }}
                  disabled={isRunning}
                >
                  <ContentCopy fontSize='small' />
                </IconButton>
              </Tooltip>
            </Fade>

            <IconButton
              onClick={handleExpandClick}
              size='small'
              color='secondary'
            >
              {expanded ? <ExpandLess /> : <ExpandMore />}
            </IconButton>
          </Stack>
        </Stack>
        {expanded && (
          <>
            <Divider />
            <Stack px={2} py={1} spacing={0.5}>
              <Stack direction='row' justifyContent='space-between'>
                <Typography color='textSecondary'>Location:</Typography>
                <Typography>
                  {scenario.parameters.lat}, {scenario.parameters.lng}
                </Typography>
              </Stack>
              <Stack direction='row' justifyContent='space-between'>
                <Typography color='textSecondary'>Wind Speed:</Typography>
                <Typography>{scenario.parameters.windSpeed} mph</Typography>
              </Stack>
              <Stack
                direction='row'
                justifyContent='space-between'
                alignItems='center'
              >
                <Typography color='textSecondary'>Wind Direction:</Typography>
                <Stack direction='row' alignItems='center' spacing={1}>
                  <DirectionPointer
                    direction={
                      (Number((scenario.parameters.windDirection + 90) % 360) +
                        180) %
                      360
                    }
                  />
                  <Typography>
                    {(scenario.parameters.windDirection + 90) % 360}°
                  </Typography>
                </Stack>
              </Stack>
              <Stack direction='row' justifyContent='space-between'>
                <Typography color='textSecondary'>
                  Fuel Moisture Content:
                </Typography>
                <Typography>
                  {Math.floor(scenario.parameters.fmc * 100)}%
                </Typography>
              </Stack>
            </Stack>
            {scenario.report && (
              <>
                <Divider />
                <Stack px={2} py={1} spacing={0.5}>
                  <Stack
                    direction='row'
                    justifyContent='space-between'
                    alignItems='center'
                  >
                    <Typography color='textSecondary'>Acres Burned:</Typography>
                    <Typography>
                      {Number(
                        scenario.report.acresBurned[
                          scenario.report.acresBurned.length - 1
                        ].toFixed(2)
                      ).toLocaleString()}
                    </Typography>
                  </Stack>
                  <Stack
                    direction='row'
                    justifyContent='space-between'
                    alignItems='center'
                  >
                    <Typography color='textSecondary'>
                      Structures Burned:
                    </Typography>
                    <Typography>
                      {scenario.report.structsBurned[
                        scenario.report.structsBurned.length - 1
                      ].toLocaleString()}
                    </Typography>
                  </Stack>
                  <Stack
                    direction='row'
                    justifyContent='space-between'
                    alignItems='center'
                  >
                    <Typography color='textSecondary'>
                      Financial Loss:
                    </Typography>
                    <Typography>
                      {`$${Number(
                        scenario.report.financialLoss[
                          scenario.report.financialLoss.length - 1
                        ]
                      ).toLocaleString()}`}
                    </Typography>
                  </Stack>
                </Stack>
              </>
            )}
          </>
        )}
      </Paper>
    </Grow>
  );
}
