import {
  closestCenter,
  DndContext,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  restrictToParentElement,
  restrictToVerticalAxis,
} from '@dnd-kit/modifiers';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { DragIndicator } from '@mui/icons-material';
import {
  Box,
  Button,
  Fade,
  Popover,
  Stack,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  natural,
  quantile,
  quantize,
  stdDev,
} from '../../../../functions/data_binning_helper';
import ColorPicker from './ColorPicker';
import { presetHues, presetShades } from '../../../../assets/preset_colors';

const SortableItem = (props) => {
  const {
    id,
    index,
    activeItemId,
    color,
    colorArray,
    updateColorArray,
    resetColorPreset,
    min,
    max,
  } = props;
  const [isHovered, setIsHovered] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id });
  const theme = useTheme();

  const handleColorChange = useCallback(
    (newColor) => {
      const newArray = [...colorArray];
      newArray[index].color = newColor;
      updateColorArray(newArray);
    },
    [index, updateColorArray]
  );

  return (
    <Stack
      py={0.5}
      spacing={0.5}
      direction='row'
      alignItems='center'
      ref={setNodeRef}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      sx={{
        transform: CSS.Transform.toString(transform),
        transition,
        backgroundColor: activeItemId
          ? activeItemId === id
            ? theme.palette.action.hover
            : 'initial'
          : 'initial',
        ':hover': {
          backgroundColor: activeItemId
            ? activeItemId === id
              ? theme.palette.action.hover
              : 'initial'
            : theme.palette.action.hover,
        },
      }}
    >
      <Fade in={isHovered}>
        <DragIndicator
          {...attributes}
          {...listeners}
          color='secondary'
          sx={{
            cursor: 'move',
            ':focus': {
              outline: 'unset',
            },
          }}
        />
      </Fade>
      <Button
        onClick={(e) => setAnchorEl(e.currentTarget)}
        sx={{
          height: 15,
          width: 30,
          minWidth: 30,
          bgcolor: color,
          ml: '-2px !important',
          mr: '2px !important',
        }}
      />
      <Popover
        open={anchorEl}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <ColorPicker
          color={color}
          setColor={handleColorChange}
          resetColorPreset={resetColorPreset}
          shades={presetShades}
          hues={presetHues}
        />
      </Popover>
      {min !== null ? (
        <TextField
          hiddenLabel
          variant='filled'
          size='small'
          value={min?.toLocaleString()}
          slotProps={{
            htmlInput: {
              sx: {
                p: 0.5,
                pr: 0,
              },
            },
          }}
          sx={{ flex: 1 }}
        />
      ) : (
        <Typography textAlign='center' sx={{ flex: 1 }}>
          Less
        </Typography>
      )}
      <Typography>-</Typography>
      {max !== null ? (
        <TextField
          hiddenLabel
          variant='filled'
          size='small'
          value={max?.toLocaleString()}
          slotProps={{
            htmlInput: {
              sx: {
                p: 0.5,
                pr: 0,
              },
            },
          }}
          sx={{ flex: 1 }}
        />
      ) : (
        <Typography textAlign='center' sx={{ flex: 1 }}>
          More
        </Typography>
      )}
    </Stack>
  );
};

export default function AssetRiskColorRanges(props) {
  const { dataset, property, updateColorArray, updateColorPreset } = props;
  const [activeItemId, setActiveItemId] = useState(null);

  const colorArray = useMemo(() => {
    return dataset.style[property].colorArray;
  }, [property, dataset.style[property].colorArray]);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleDragEnd = (e) => {
    const { active, over } = e;
    setActiveItemId(null);

    if (active.id !== over.id) {
      const oldIndex = colorArray.map((item) => item.id).indexOf(active.id);
      const newIndex = colorArray.map((item) => item.id).indexOf(over.id);
      const oldMin = colorArray[oldIndex].min;
      const newArray = arrayMove(colorArray, oldIndex, newIndex);

      if (oldIndex < newIndex) {
        for (let i = newIndex; i > oldIndex; i--) {
          newArray[i].min = newArray[i - 1].min;
        }
      } else {
        for (let i = newIndex; i < oldIndex; i++) {
          newArray[i].min = newArray[i + 1].min;
        }
      }
      newArray[oldIndex].min = oldMin;
      updateColorArray(newArray);
      updateColorPreset('');
    }
  };

  useEffect(() => {
    const parameter = dataset.style[property].parameter;
    const data = dataset.data.map((object) => object[parameter]);
    let minBreakpoints = [];
    switch (dataset.style[property].colorScale) {
      case 'Quantize':
        minBreakpoints = quantize(
          data,
          colorArray.length,
          dataset.minValues[parameter],
          dataset.maxValues[parameter]
        );
        break;
      case 'Quantile':
        minBreakpoints = quantile(data, colorArray.length);
        break;
      case 'Natural':
        minBreakpoints = natural(data, colorArray.length);
        break;
      case 'Standard Deviation':
        minBreakpoints = stdDev(data, colorArray.length);
        break;
      default:
        console.error('Missing colorScale', dataset);
        return;
    }

    const newArray = colorArray.map((object, index) => {
      object.min = minBreakpoints[index];
      return object;
    });

    updateColorArray(newArray);
  }, [
    property,
    dataset.style[property].colorScale,
    dataset.style[property].parameter,
  ]);

  return (
    <Box>
      <Stack direction='row' justifyContent='space-between'>
        <Typography>
          Min:{' '}
          {dataset.minValues[
            dataset.style[property].parameter
          ].toLocaleString()}
        </Typography>
        <Typography>
          Max:{' '}
          {dataset.maxValues[
            dataset.style[property].parameter
          ].toLocaleString()}
        </Typography>
      </Stack>
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragStart={(e) => setActiveItemId(e.active.id)}
        onDragEnd={handleDragEnd}
        modifiers={[restrictToVerticalAxis, restrictToParentElement]}
      >
        <SortableContext
          items={colorArray}
          strategy={verticalListSortingStrategy}
        >
          {colorArray.map((item, index) => {
            return (
              <SortableItem
                key={item.id}
                index={index}
                id={item.id}
                activeItemId={activeItemId}
                color={item.color}
                colorArray={colorArray}
                updateColorArray={updateColorArray}
                resetColorPreset={() => updateColorPreset('')}
                min={item.min}
                max={
                  index < colorArray.length - 1
                    ? colorArray[index + 1].min
                    : null
                }
              />
            );
          })}
        </SortableContext>
      </DndContext>
    </Box>
  );
}
