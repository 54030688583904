import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import GoogleMapsCustomControl from '../../map-controls/GoogleMapsCustomControl';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Stack, Typography } from '@mui/material';
import { useFirePerimetersLayerState } from '../../../contexts/FirePerimetersLayerStateProvider';
import dayjs from 'dayjs';
import { useEffect } from 'react';
import { useFirePerimetersLayer } from '../../../hooks/useFirePerimetersLayer';

export default function FirePerimetersTimeRangePicker(props) {
  const { firePerimetersTimeRange, setFirePerimetersTimeRange } =
    useFirePerimetersLayerState();
  const { updateFirePerimetersLayer } = useFirePerimetersLayer();

  useEffect(() => {
    updateFirePerimetersLayer();
  }, [firePerimetersTimeRange]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <GoogleMapsCustomControl BoxProps={{ sx: { pb: 1, pt: 0.5, px: 2 } }}>
        <Typography variant='caption' sx={{ color: 'rgba(0, 0, 0, 0.6)' }}>
          Fire Perimeters
        </Typography>
        <Stack direction='row' spacing={2} alignItems='center'>
          <DatePicker
            value={firePerimetersTimeRange[0]}
            onChange={(newValue) =>
              setFirePerimetersTimeRange((prev) => {
                if (newValue <= prev[1]) {
                  return [newValue, prev[1]];
                } else {
                  return [newValue, newValue];
                }
              })
            }
            views={['year']}
            minDate={dayjs().year(2016)}
            maxDate={dayjs().year(2025)}
            slotProps={{
              field: { variant: 'standard' },
              popper: {
                sx: {
                  '& .MuiDateCalendar-root': {
                    height: 'fit-content',
                  },
                },
              },
            }}
            sx={{
              width: '75px',
            }}
          />
          <Typography variant='body1'>-</Typography>
          <DatePicker
            value={firePerimetersTimeRange[1]}
            onChange={(newValue) =>
              setFirePerimetersTimeRange((prev) => {
                if (newValue >= prev[0]) {
                  return [prev[0], newValue];
                } else {
                  return [newValue, newValue];
                }
              })
            }
            views={['year']}
            minDate={dayjs().year(2016)}
            maxDate={dayjs().year(2025)}
            slotProps={{
              field: { variant: 'standard' },
              popper: {
                sx: {
                  '& .MuiDateCalendar-root': {
                    height: 'fit-content',
                  },
                },
              },
            }}
            sx={{
              width: '75px',
            }}
          />
        </Stack>
      </GoogleMapsCustomControl>
    </LocalizationProvider>
  );
}
