import {
  LocalizationProvider,
  DateTimePicker,
  renderDigitalClockTimeView,
} from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useWindLayerState } from '../../../contexts/WindLayerStateProvider';
import GoogleMapsCustomControl from '../../map-controls/GoogleMapsCustomControl';
import dayjs from 'dayjs';
import { useWindLayer } from '../../../hooks/useWindLayer';

export default function WindDateTimePicker(props) {
  const { DateTimePickerProps } = props;
  const [selectedDateTime, setSelectedDateTime] = useState(null);
  const { windManifest, activeWindIndex, setActiveWindIndex } =
    useWindLayerState();
  const { updateWindLayer } = useWindLayer();
  const prevSelectedDateTime = useRef(null);
  const trueSelectedDateTime = useRef(null);

  const handleDisableYear = useCallback(
    (year) => {
      return !Object.values(windManifest).some((obj) =>
        dayjs.unix(Number(obj.forecast)).isSame(year, 'year')
      );
    },
    [windManifest]
  );

  // const handleDisableMonth = useCallback(
  // 	(month) => {
  // 		return !Object.values(windManifest).some((obj) =>
  // 			dayjs.unix(Number(obj.forecast)).isSame(month, 'month')
  // 		);
  // 	},
  // 	[windManifest]
  // );

  const handleDisableDate = useCallback(
    (date) => {
      return !Object.values(windManifest).some((obj) =>
        dayjs.unix(Number(obj.forecast)).isSame(date, 'day')
      );
    },
    [windManifest]
  );

  const handleDisableTime = useCallback(
    (timeValue, view) => {
      if (view === 'hours' || view === 'minutes') {
        return !Object.values(windManifest).some((obj) =>
          dayjs.unix(Number(obj.forecast)).isSame(timeValue, 'hour')
        );
      }
      return false;
    },
    [windManifest]
  );

  const handleChange = (newValue) => {
    setSelectedDateTime(newValue);
    trueSelectedDateTime.current = newValue;
  };

  const handleClose = useCallback(() => {
    if (!trueSelectedDateTime.current) return;
    const newIndex = Object.values(windManifest).findIndex(
      (obj) => trueSelectedDateTime.current.unix() === obj.forecast
    );
    if (newIndex !== -1) {
      setActiveWindIndex(newIndex);
      updateWindLayer(newIndex);
      prevSelectedDateTime.current = trueSelectedDateTime.current;
    } else {
      setSelectedDateTime(prevSelectedDateTime.current);
    }
  }, [selectedDateTime, windManifest, setActiveWindIndex]);

  useEffect(() => {
    if (windManifest && activeWindIndex) {
      const forecast = dayjs.unix(windManifest[activeWindIndex].forecast);
      setSelectedDateTime(forecast);
      prevSelectedDateTime.current = forecast;
    }
  }, [windManifest, activeWindIndex]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <GoogleMapsCustomControl
        BoxProps={{ sx: { p: 1, px: 2, height: 'fit-content' } }}
      >
        <DateTimePicker
          value={selectedDateTime}
          label='Wind Forecast'
          onChange={handleChange}
          onClose={handleClose}
          shouldDisableYear={handleDisableYear}
          shouldDisableDate={handleDisableDate}
          shouldDisableTime={handleDisableTime}
          skipDisabled
          timeSteps={{ minutes: 60 }}
          ampm={true}
          viewRenderers={{
            hours: renderDigitalClockTimeView,
          }}
          slotProps={{
            field: {
              variant: 'standard',
            },
            // layout: {
            // 	sx: {
            // 		'.MuiMultiSectionDigitalClockSection-root::after': {
            // 			display: 'none',
            // 		},
            // 	},
            // },
          }}
          {...DateTimePickerProps}
        />
      </GoogleMapsCustomControl>
    </LocalizationProvider>
  );
}
